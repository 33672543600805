import React, { Component } from 'react';

import './base.css';

import {Auth0Context} from '../../infrastructure/Auth0/Auth0Provider';
import {Button} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {connect} from "react-redux";
import {MainState} from "../../reducers/MainState";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {
    deregisterFromInstanceAsync,
    getManagedInstances, registerToInstanceAsync,
} from "../../types/actions/AsyncActionCreators";
import {Instance} from "../../types/data/Instance";


interface IInstanceManagementPageState {
}

interface IInstanceManagementPageProps {
    managedInstances: {[instanceName: string] :Instance};
}

interface IInstanceManagementPageDispatch {
    getManagedInstances: (authToken: string) => any;
    registerToInstance: (authToken: string, authId: string, authName: string, instanceName: string) => any;
    deregisterFromInstance: (authToken: string, authId: string, instanceName: string) => any;
}

class InstanceManagementPage extends Component<IInstanceManagementPageProps & IInstanceManagementPageDispatch, IInstanceManagementPageState> {

    static contextType = Auth0Context;

    constructor(props: IInstanceManagementPageProps & IInstanceManagementPageDispatch) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.context.callWithToken((authToken: string) => {
            this.props.getManagedInstances(authToken)
        })
    }

    componentDidUpdate(prevProps: IInstanceManagementPageProps, prevState: IInstanceManagementPageState) {
        let instancesChanged = false
        for (const instanceName in prevProps.managedInstances) {
            if ((prevProps.managedInstances[instanceName].registeredUsers &&
                Object.keys(prevProps.managedInstances[instanceName].registeredUsers).length
                    != Object.keys(this.props.managedInstances[instanceName].registeredUsers).length) ||
                (this.props.managedInstances[instanceName].registeredUsers &&
                    !prevProps.managedInstances[instanceName].registeredUsers)
            ) {
                instancesChanged = true;
                break;
            }
        }
        if (instancesChanged) {
            console.log('Update managed instances')
            this.context.callWithToken((authToken: string) => {
                this.props.getManagedInstances(authToken)
            })
        }
    }

    toggleInstanceRegistration(instance: Instance, authId: string, authName: string) {
        if (instance.registeredUsers && instance.registeredUsers.hasOwnProperty(authId)) {
            console.log('Deregistering from instance ' + instance.name);
            this.context.callWithToken((authToken: string) => {
                this.props.deregisterFromInstance(authToken, authId, instance.name);
            });
        } else {
            console.log('Registering to instance ' + instance.name);
            this.context.callWithToken((authToken: string) => {
                this.props.registerToInstance(authToken, authId, authName, instance.name);
            });
        }

    }

    hasInstanceWithFrontend() {
        for (let instancesName in this.props.managedInstances) {
            const instance = this.props.managedInstances[instancesName];
            if (instance.frontendUrl) {
                return true;
            }
        }
        return false;
    }

    formatFrontendUrl(url: string) {
        if (!url.startsWith('https://')) {
            return `https://${url}`;
        } else {
            return url;
        }
    }

    render() {
        let tableRows = Object.values(this.props.managedInstances).map((instance: any) => (
            <tr>
                <th>{instance.name}</th>
                <th>{instance.status}</th>
                <th>{instance.ipAddress}</th>
                {this.hasInstanceWithFrontend()
                    ? instance.frontendUrl ? <th><a href={this.formatFrontendUrl(instance.frontendUrl)} target={"_blank"}>
                        {instance.frontendUrl}
                    </a></th> : <th/>
                    : null}
                <th>{instance.registeredUsers ? Object.values(instance.registeredUsers) : ''}</th>
                <th><Button type="button"
                            onClick={() => this.toggleInstanceRegistration(instance, this.context.user.sub, this.context.user.nickname)}>
                    {instance.registeredUsers && instance.registeredUsers.hasOwnProperty(this.context.user.sub) ? 'Deregister' : 'Register'}
                </Button></th>
            </tr>))

        return (
            <div>
                <Table striped bordered hover responsive>
                    <thead>
                    <tr>
                        <th>Instance name</th>
                        <th>status</th>
                        <th>IP Address</th>
                        {this.hasInstanceWithFrontend() ? <th>Frontend Url</th> : null}
                        <th>Registered users</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default connect((state: MainState, _ownProps: IInstanceManagementPageProps) => {
    return {
        managedInstances: state.managedInstances
    };
}, (dispatch: ThunkDispatch<MainState, any, Action>) => {
    return {
        getManagedInstances: (authToken: string) => dispatch(getManagedInstances(authToken)),
        registerToInstance: (authToken: string, authId: string, authName: string, instanceName: string) =>
            dispatch(registerToInstanceAsync(authToken, authId, authName, instanceName)),
        deregisterFromInstance: (authToken: string, authId: string, instanceName: string) =>
            dispatch(deregisterFromInstanceAsync(authToken, authId, instanceName)),
    };
})(InstanceManagementPage)
