export const API_BASE = process.env.REACT_APP_API_BASE ? process.env.REACT_APP_API_BASE : "https://5ypoynbplj.execute-api.eu-west-2.amazonaws.com/dev";

export const companyName = 'Panakeia Technologies';
export const companyWebsite = 'https://panakeia.ai/';
export const suiteName = 'PANdiagnostics';
export const suiteDescription = 'Panakeia Deep Diagnostic Suite';
export const contactMail = 'contact.panakeia.ai';
export const registration = 'UK & Wales registration number: 11696687';
export const streetAddress = 'Entrepreneurship Institute, Bush House';
export const locality = '30 Aldwych';
export const region = 'London';
export const postalCode = 'WC2B 4BG';
export const countryName = 'United Kingdom';

const baseUrl = window.location.origin;

export const AUTH_CONFIG = {
    "domain": "dev-zg07n4f7.eu.auth0.com",
    "client_id": "OkRnQFDyZGwhbXo43z9F6Ab4bPCHxbW8",
    "response_type": 'token id_token',
    "audience": 'https://7ezlmm9ibj.execute-api.eu-west-2.amazonaws.com/Prod',
    "redirect_uri": `${baseUrl}/callback`,
    "logout_uri": `${baseUrl}`,
    "scope": "openid profile api-access"
};

export const fetchInterval = 1e4;