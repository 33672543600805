import {IBaseAction} from "./IBaseAction";
import {Action, Dispatch} from "redux";

import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {API_BASE} from "../../constants";
import {ThunkDispatch} from "redux-thunk";

import fileDownload from 'js-file-download';

import {
    uploadAnnotationError,
    uploadAnnotationProgress,
    uploadAnnotationSuccess,
    addAnnotationTypes,
    addEthicsApprovals,
    uploadImageError,
    uploadImageProgress,
    addImagesSuccess,
    addImagesError,
    uploadImageSuccess,
    addLabSuccess,
    addLabsSuccess,
    addLabsError,
    addPatientsSuccess,
    addPatientsError,
    addProceduresSuccess,
    addProceduresError,
    addAnnotationsSuccess,
    addAnnotationsError,
    addScanners,
    downloadAnnotation,
    downloadAnnotationError,
    downloadAnnotationProgress,
    downloadAnnotationSuccess,
    downloadImageRequest,
    downloadImageError,
    downloadImageProgress,
    downloadImageSuccess,
    addLabRequest,
    addLabError,
    editLabRequest,
    editLabSuccess,
    editLabError,
    deleteLabRequest,
    deleteLabSuccess,
    deleteLabError,
    addPatientRequest,
    addPatientSuccess,
    addPatientError,
    editPatientRequest,
    editPatientSuccess,
    editPatientError,
    deletePatientRequest,
    deletePatientSuccess,
    deletePatientError,
    addProcedureRequest,
    addProcedureSuccess,
    addProcedureError,
    editProcedureRequest,
    editProcedureSuccess,
    editProcedureError,
    deleteProcedureRequest,
    deleteProcedureSuccess,
    deleteProcedureError,
    addImageRequest,
    addImageSuccess,
    addImageError,
    editImageRequest,
    editImageSuccess,
    editImageError,
    deleteImageSuccess,
    deleteImageRequest,
    deleteImageError,
    addAnnotationSuccess,
    addAnnotationRequest,
    addAnnotationError,
    editAnnotationRequest,
    editAnnotationSuccess,
    editAnnotationError,
    deleteAnnotationRequest,
    deleteAnnotationSuccess,
    deleteAnnotationError,
    uploadImageRequest,
    acquireEditLockRequest,
    acquireEditLockSuccess,
    acquireEditLockError,
    releaseEditLockRequest,
    releaseEditLockSuccess,
    releaseEditLockError,
    addManagedInstances,
    registerToInstance,
    deregisterFromInstance,
} from "./ActionCreators";
import {MainState} from "../../reducers/MainState";
import {EDIT_STATUS} from "../data/Common";

const uuidv4 = require('uuid/v4');

export const loadEthicsApprovals = (authToken: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/ethicsApprovals';

    axios.get(url, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            let ethicsApprovals: { [uuid: string]: string } = {};
            for (let uuid in res.data.ethicsApprovals) {
                ethicsApprovals[uuid] = res.data.ethicsApprovals[uuid]['name']
            }
            dispatch(addEthicsApprovals(res.data.timestamp, ethicsApprovals))
        })
        .catch((err) => {
            console.log('Error loading ethicsApprovals', err)
        })
};

export const loadLabs = (authToken: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/labs';

    axios.get(url, {headers: {'Authorization': `Bearer ${authToken}`}}, )
        .then((res: AxiosResponse) => {
            let labs = res.data.labs;
            dispatch(addLabsSuccess(res.data.timestamp, labs));
        })
        .catch((err) => {
            console.log('Error loading labs', err);
            dispatch(addLabsError(err))
        })
};

export const loadPatients = (authToken: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/patients';

    axios.get(url, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            let patients = res.data.patients;
            dispatch(addPatientsSuccess(res.data.timestamp, patients));
        })
        .catch((err) => {
            console.log('Error loading patients', err);
            dispatch(addPatientsError(err));
        })
};

export const loadProcedures = (authToken: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/procedures';

    axios.get(url, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            let procedures = res.data.procedures;
            dispatch(addProceduresSuccess(res.data.timestamp, procedures));
        })
        .catch((err) => {
            console.log('Error loading procedures', err);
            dispatch(addProceduresError(err))
        })
};

export const loadImages = (authToken: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/images';

    axios.get(url, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            let images = res.data.images;
            let scanners = res.data.scanners;
            dispatch(addImagesSuccess(res.data.timestamp, images));
            dispatch(addScanners(res.data.timestamp, scanners));
        })
        .catch((err) => {
            console.log('Error loading images', err);
            dispatch(addImagesError(err))
        })
};

export const loadAnnotations = (authToken: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/annotations';
    axios.get(url, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            let annotations = res.data.annotations;
            let annotationTypes = res.data.annotationTypes;
            dispatch(addAnnotationsSuccess(res.data.timestamp, annotations));
            dispatch(addAnnotationTypes(res.data.timestamp, annotationTypes));
        })
        .catch((err) => {
            dispatch(addAnnotationsError(err));
            console.log('Error loading annotations and annotationTypes', err);
        })
};

export const addLabAsync = (authToken: string, labData: any, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/labs/add';
    let tempId = uuidv4();
    dispatch(addLabRequest(tempId));
    axios.post(url, {user, ...labData}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            labData.uuid = res.data.uuid;
            labData.creationDate = res.data.creationDate;
            dispatch(addLabSuccess(tempId, labData));
        })
        .catch(err => {
            dispatch(addLabError(tempId, err));
        });
};

export const editLabAsync = (authToken: string, labData: any, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/labs/edit';
    dispatch(editLabRequest(labData.uuid));
    axios.post(url, {user, ...labData}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(editLabSuccess(labData));
        })
        .catch(err => {
            dispatch(editLabError(labData.uuid, err))
        });
};

export const deleteLabAsync = (authToken: string, uuid: string, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/labs/delete';
    dispatch(deleteLabRequest(uuid));
    axios.post(url, {uuid, user}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((_res: AxiosResponse) => {
            dispatch(deleteLabSuccess(uuid));
        })
        .catch(err => {
            dispatch(deleteLabError(uuid, err))
        });
};

export const addPatientAsync = (authToken: string, patientData: any, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/patients/add';
    let tempId = uuidv4();
    dispatch(addPatientRequest(tempId));
    axios.post(url, {user, ...patientData}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            patientData.uuid = res.data.uuid;
            patientData.creationDate = res.data.creationDate;
            dispatch(addPatientSuccess(tempId, patientData));
        })
        .catch(err => {
            dispatch(addPatientError(tempId, err));
        });
};

export const editPatientAsync = (authToken: string, prevLabUuid: string, patientData: any, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/patients/edit';
    dispatch(editPatientRequest(patientData.uuid));
    axios.post(url, {user, prevLabUuid, ...patientData}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(editPatientSuccess(patientData));
        })
        .catch(err => {
            dispatch(editPatientError(patientData.uuid, err))
        });
};

export const deletePatientAsync = (authToken: string, labUuid: string, uuid: string, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/patients/delete';
    dispatch(deletePatientRequest(uuid));
    axios.post(url, {labUuid, uuid, user}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(deletePatientSuccess(uuid));
        })
        .catch(err => {
            dispatch(deletePatientError(uuid, err))
        });
};

export const addProcedureAsync = (authToken: string, labUuid: string, procedureData: any, images: any[], user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/procedures/add';
    let tempId = uuidv4();
    dispatch(addProcedureRequest(tempId));
    axios.post(url, {user, ...procedureData}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            procedureData.uuid = res.data.uuid;
            procedureData.creationDate = res.data.creationDate;
            dispatch(addProcedureSuccess(tempId, procedureData));

            for (let image of images) {
                dispatch(addImageAsync(authToken, labUuid, procedureData.patientUuid, {
                    procedureUuid: procedureData.uuid,
                    ...image
                }, user));
            }
        })
        .catch(err => {
            dispatch(addProcedureError(tempId, err))
        });
};

export const editProcedureAsync = (authToken: string, labUuid: string, prevPatientUuid: string, procedureData: any, images: any[], user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/procedures/edit';
    dispatch(editProcedureRequest(procedureData.uuid));
    axios.post(url, {user, labUuid, prevPatientUuid, ...procedureData}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(editProcedureSuccess(procedureData));

            for (let image of images) {
                let {editStatus, annotations, ...imageData} = image;
                switch (editStatus) {
                    case EDIT_STATUS.ADDED:
                        dispatch(addImageAsync(authToken, labUuid, procedureData.patientUuid, {
                            procedureUuid: procedureData.uuid,
                            ...imageData
                        }, user));
                        break;

                    case EDIT_STATUS.EDITED:
                        dispatch(editImageAsync(authToken, labUuid, procedureData.patientUuid, {
                            ...imageData
                        }, user));
                        break;

                    case EDIT_STATUS.DELETED:
                        dispatch(deleteImageAsync(authToken, labUuid, procedureData.patientUuid, procedureData.uuid, imageData.uuid));
                        break;
                    default:
                        if (annotations) {
                            dispatch(processAnnotationsAsync(authToken, labUuid, procedureData.patientUuid, procedureData.uuid, imageData.uuid, annotations, user))
                        }
                }

            }
        })
        .catch(err => {
            dispatch(editProcedureError(procedureData.uuid, err))
        });
};

export const deleteProcedureAsync = (authToken: string, labUuid: string, patientUuid: string, uuid: string, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/procedures/delete';
    dispatch(deleteProcedureRequest(uuid));
    axios.post(url, {labUuid, patientUuid, uuid, user}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(deleteProcedureSuccess(patientUuid, uuid));
        })
        .catch(err => {
            dispatch(deleteProcedureError(uuid, err))
        });
};

export const addImageAsync = (authToken: string, labUuid: string, patientUuid: string, data: any, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/images/add';
    let {file, annotations, ...imageData} = data;
    let tempId = uuidv4();
    dispatch(addImageRequest(tempId));
    axios.post(url, {user, ...imageData}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            imageData.uuid = res.data.uuid;
            imageData.creationDate = res.data.creationDate;
            dispatch(addImageSuccess(tempId, imageData));
            dispatch(uploadImageAsync(authToken, labUuid, patientUuid, imageData.procedureUuid, imageData.uuid, imageData.name, file));
            if (annotations) {
                for (let annotation of annotations) {
                    dispatch(addAnnotationAsync(authToken, labUuid, patientUuid, imageData.procedureUuid, {
                        imageUuid: imageData.uuid,
                        ...annotation
                    }, user))
                }
            }
        })
        .catch(err => {
            dispatch(addImageError(tempId, err));
        })

};

export const uploadImageAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, imageUuid: string, name: string, file: any) => (dispatch: ThunkDispatch<MainState, any, Action>) => {    if (file) {
        let url = API_BASE + '/getSignedUrl';
        dispatch(uploadImageRequest(imageUuid, procedureUuid, name))
        let fileType = file.name.includes(".") ? file.name.split('.').pop() : "";
        axios.post(url, {
            type: 'upload_image',
            labUuid,
            patientUuid,
            procedureUuid,
            imageUuid,
            fileType
        }, {headers: {authorization: `Bearer ${authToken}`}})
            .then(response => {
                let signedRequest = response.data.signedRequest;
                let fileId = response.data.fileId;
                let prevProgress = 0;

                // Put the fileType in the headers for the upload
                let options = {
                    headers: {
                        'Content-Type': fileType
                    },
                    onUploadProgress: function (progressEvent: any) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        if (percentCompleted > prevProgress) {
                            dispatch(uploadImageProgress(imageUuid, procedureUuid, percentCompleted));
                            prevProgress = percentCompleted;
                        }
                    }
                };
                axios.put(signedRequest, file, options)
                    .then(result => {
                        dispatch(updateImageAsync(authToken, imageUuid, procedureUuid, fileId, fileType));
                    })
                    .catch(error => {
                        dispatch(uploadImageError(imageUuid, procedureUuid, error));
                    })
            })
            .catch(error => {
                dispatch(uploadImageError(imageUuid, procedureUuid, error));
            });
    } else {
        dispatch(uploadImageError(imageUuid, procedureUuid, 'Missing file'));
    }
};

export const updateImageAsync = (authToken: string, uuid: string, procedureUuid: string, fileId: string, fileType: string) => (dispatch: Dispatch<IBaseAction<any>>) => {
    let url = API_BASE + '/images/update';

    axios.post(url, {
        uuid,
        fileId,
        fileType
    }, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(uploadImageSuccess(uuid, procedureUuid, fileId, fileType));
        }).catch((err => {
        dispatch(uploadImageError(uuid, procedureUuid, err));
    }))
};

export const editImageAsync = (authToken: string, labUuid: string, patientUuid: string, data: any, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/images/edit';
    let {file, annotations, ...imageData} = data;
    dispatch(editImageRequest(imageData.uuid));
    axios.post(url, imageData, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(editImageSuccess(imageData));
            if (file) {
                dispatch(uploadImageAsync(authToken, labUuid, patientUuid, imageData.procedureUuid, imageData.uuid, imageData.name, file))
            }
            if (annotations) {
                dispatch(processAnnotationsAsync(authToken, labUuid, patientUuid, imageData.procedureUuid, imageData.uuid, annotations, user))
            }
        })
        .catch(err => {
            dispatch(editImageError(imageData.uuid, err))
        });
};

export const deleteImageAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, uuid: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/images/delete';
    dispatch(deleteImageRequest(uuid));
    axios.post(url, {labUuid, patientUuid, procedureUuid, uuid}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(deleteImageSuccess(procedureUuid, uuid));
        })
        .catch(err => {
            dispatch(deleteImageError(uuid, err))
        });
};

export const processAnnotationsAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, imageUuid: string, annotations: any[], user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    for (let annotation of annotations) {
        let {editStatus, ...annotationData} = annotation;
        switch(editStatus) {
            case EDIT_STATUS.ADDED:
                annotationData.imageUuid = imageUuid;
                dispatch(addAnnotationAsync(authToken, labUuid, patientUuid, procedureUuid, annotationData, user));
                break;
            case EDIT_STATUS.EDITED:
                dispatch(editAnnotationAsync(authToken, labUuid, patientUuid, procedureUuid, annotationData));
                break;
            case EDIT_STATUS.DELETED:
                dispatch(deleteAnnotationAsync(authToken, labUuid, patientUuid, procedureUuid, annotationData.imageUuid, annotationData.uuid));
                break;
        }
    }
};

export const addAnnotationAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, data: any, user: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/annotations/add';
    let {file, ...annotationData} = data;
    let tempId = uuidv4();
    dispatch(addAnnotationRequest(tempId));
    axios.post(url, {user, ...annotationData}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            annotationData.uuid = res.data.uuid;
            annotationData.creationDate = res.data.creationDate;
            dispatch(addAnnotationSuccess(tempId, annotationData));
            dispatch(uploadAnnotationAsync(authToken, labUuid, patientUuid, procedureUuid, annotationData.imageUuid, annotationData.uuid, annotationData.name, file));
        })
        .catch(err => {
            dispatch(addAnnotationError(tempId, err));
        })

};

export const uploadAnnotationAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, imageUuid: string, annotationUuid: string, name: string, file: any) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    if (file) {
        let url = API_BASE + '/getSignedUrl';

        let fileType = file.name.includes(".") ? file.name.split('.').pop() : "";
        axios.post(url, {
            type: 'upload_annotation',
            labUuid,
            patientUuid,
            procedureUuid,
            imageUuid,
            annotationUuid,
            fileType
        }, {headers: {authorization: `Bearer ${authToken}`}})
            .then(response => {
                let signedRequest = response.data.signedRequest;
                let fileId = response.data.fileId;

                let prevProgress = 0;

                // Put the fileType in the headers for the upload
                let options = {
                    headers: {
                        'Content-Type': fileType
                    },
                    onUploadProgress: function (progressEvent: any) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        if (percentCompleted > prevProgress) {
                            prevProgress = percentCompleted;
                            dispatch(uploadAnnotationProgress(annotationUuid, imageUuid, percentCompleted));
                        }
                    }
                };
                axios.put(signedRequest, file, options)
                    .then(result => {
                        dispatch(updateAnnotationAsync(authToken, annotationUuid, imageUuid, fileId));
                    })
                    .catch(error => {
                        dispatch(uploadAnnotationError(annotationUuid, imageUuid, error))
                    })
            })
            .catch(error => {
                dispatch(uploadAnnotationError(annotationUuid, imageUuid, error))
            });
    } else {
        dispatch(uploadAnnotationError(annotationUuid, procedureUuid, 'Missing annotation file'))
    }
};

export const updateAnnotationAsync = (authToken: string, uuid: string, imageUuid: string, fileId: string) => (dispatch: Dispatch<IBaseAction<any>>) => {
    let url = API_BASE + '/annotations/update';
    axios.post(url, {
        uuid,
        fileId
    }, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(uploadAnnotationSuccess(uuid, imageUuid, fileId));
        })
        .catch((err => {
            dispatch(uploadAnnotationError(uuid, imageUuid, err));
        }))
};


export const editAnnotationAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, data: any) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/annotations/edit';
    let {file, ...annotationData} = data;
    dispatch(editAnnotationRequest(annotationData.uuid));
    axios.post(url, annotationData, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(editAnnotationSuccess(annotationData));
            if (file) {
                dispatch(uploadAnnotationAsync(authToken, labUuid, patientUuid, procedureUuid, annotationData.imageUuid, annotationData.uuid, annotationData.name, file))
            }
        })
        .catch(err => {
            dispatch(editAnnotationError(annotationData.uuid, err))
        });
};

export const deleteAnnotationAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, imageUuid: string, uuid: string) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = API_BASE + '/annotations/delete';
    dispatch(deleteAnnotationRequest(uuid));
    axios.post(url, {labUuid, patientUuid, procedureUuid, imageUuid, uuid}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(deleteAnnotationSuccess(imageUuid, uuid));
        })
        .catch(err => {
            dispatch(deleteAnnotationError(uuid, err))
        });
};


export const downloadImageAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, imageUuid: string, fileType: string) => (dispatch: Dispatch<IBaseAction<any>>) => {

    let url = API_BASE + '/getSignedUrl';

    dispatch(downloadImageRequest(imageUuid, procedureUuid));

    axios.post(url, {
        type: 'download_image',
        patientUuid,
        labUuid,
        procedureUuid,
        imageUuid,
        fileType
    }, {headers: {authorization: `Bearer ${authToken}`}})
        .then((response: AxiosResponse) => {
            let signedRequest = response.data.signedRequest;
            let prevProgress = 0;

            let options: AxiosRequestConfig = {
                responseType: "blob",
                onDownloadProgress: function (progressEvent: any) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (percentCompleted > prevProgress) {
                        dispatch(downloadImageProgress(imageUuid, procedureUuid, percentCompleted));
                        prevProgress = percentCompleted;
                    }
                }
            };
            axios.get(signedRequest, options)
                .then((response) => {
                    fileDownload(response.data, `${imageUuid}.${fileType}`);
                    dispatch(downloadImageSuccess(imageUuid, procedureUuid));
                })
                .catch((e: any) => {
                    dispatch(downloadImageError(imageUuid, procedureUuid, e));
                })
        })
        .catch((e: any) => {
            dispatch(downloadImageError(imageUuid, procedureUuid, e));
        })
};

export const downloadAnnotationAsync = (authToken: string, labUuid: string, patientUuid: string, procedureUuid: string, imageUuid: string, annotationUuid: string) => (dispatch: Dispatch<IBaseAction<any>>) => {
    let url = API_BASE + '/getSignedUrl';

    dispatch(downloadAnnotation(annotationUuid, imageUuid));

    axios.post(url, {
        type: 'download_annotation',
        patientUuid,
        labUuid,
        procedureUuid,
        imageUuid,
        annotationUuid,
        fileType: 'json'
    }, {headers: {authorization: `Bearer ${authToken}`}})
        .then((response: AxiosResponse) => {
            let signedRequest = response.data.signedRequest;

            let prevProgress = 0;
            let options = {
                onDownloadProgress: function (progressEvent: any) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    if (percentCompleted > prevProgress) {
                        dispatch(downloadAnnotationProgress(annotationUuid, imageUuid, percentCompleted));
                        prevProgress = percentCompleted;
                    }
                }
            };
            axios.get(signedRequest, options)
                .then((response: AxiosResponse) => {
                    let data;
                    if (typeof response.data === 'string') {
                        data = response.data
                    } else {
                        data = JSON.stringify(response.data)
                    }
                    fileDownload(data, `${annotationUuid}.json`);
                    dispatch(downloadAnnotationSuccess(annotationUuid, imageUuid));
                })
                .catch((err: any) => {
                    dispatch(downloadAnnotationError(annotationUuid, imageUuid, err))
                })
        })
        .catch((e: any) => {
            dispatch(downloadAnnotationError(annotationUuid, imageUuid, e))
        })
};

export const acquireEditLockAsync = (authToken: string, table: string, uuid: string, user: string) => (dispatch: Dispatch<IBaseAction<any>>) => {
    let url = API_BASE + '/editLock/acquire';

    dispatch(acquireEditLockRequest(table, uuid));

    axios.post(url, {table, uuid, user}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((response: AxiosResponse) => {
            let lockDate = response.data.lockDate;
            dispatch(acquireEditLockSuccess(uuid, lockDate));
        })
        .catch((err: any) => {
            dispatch(acquireEditLockError(uuid, err))
        })
};


export const releaseEditLockAsync = (authToken: string, table: string, uuid: string, user: string) => (dispatch: Dispatch<IBaseAction<any>>) => {
    let url = API_BASE + '/editLock/release';

    dispatch(releaseEditLockRequest(uuid));

    axios.post(url, {table, uuid, user}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((response: AxiosResponse) => {
            dispatch(releaseEditLockSuccess(uuid));
        })
        .catch((err: any) => {
            dispatch(releaseEditLockError(uuid, err))
        })
};


export const getManagedInstances = (authToken: string) => (dispatch: Dispatch<IBaseAction<any>>) => {
    let url = API_BASE + '/instances';

    axios.get(url, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(addManagedInstances(res.data.instances))
        })
        .catch((err: any) => {
            console.log('Error getting managed instances', err)
        })
}

export const registerToInstanceAsync = (authToken: string, authId: string, authName: string, instanceName: string) =>
    (dispatch: Dispatch<IBaseAction<any>>) => {
    let url = `${API_BASE}/instance/${instanceName}/register`;

    axios.post(url, {}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(registerToInstance(authId, authName, instanceName))
        })
            .catch((err: any) => {
                console.log(`Error registering to instance ${instanceName}`, err)
        })
}

export const deregisterFromInstanceAsync = (authToken: string, authId: string, instanceName: string) =>
    (dispatch: Dispatch<IBaseAction<any>>) => {
    let url = `${API_BASE}/instance/${instanceName}/deregister`;

    axios.post(url, {}, {headers: {authorization: `Bearer ${authToken}`}})
        .then((res: AxiosResponse) => {
            dispatch(deregisterFromInstance(authId, instanceName))
        })
            .catch((err: any) => {
                console.log(`Error deregistering from instance ${instanceName}`, err)
        })
}
