import React, {Component} from 'react';

import './base.css';

import {Lab} from "../../types/data/Lab";
import LabTable from "./LabTable";
import {MainState} from "../../reducers/MainState";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {Redirect} from "react-router";

import {fetchInterval} from "../../constants";
import {
    loadEthicsApprovals,
    loadLabs,
} from "../../types/actions/AsyncActionCreators";
import {Auth0Context} from '../../infrastructure/Auth0/Auth0Provider';

interface ILabOverviewProps {
    labs?: { [uuid: string]: Lab };
    ethicsApprovals?: { [id: string]: string };
    labsUpdateDate?: number
}

interface ILabOverviewState {
    addLab: boolean;
    selectedUuid: string;
}

interface ILabOverviewDispatch {
    loadLabs: (authToken: string) => void;
    loadEthicsApprovals: (authToken: string) => void;
}

const initialState: ILabOverviewState = {
    addLab: false,
    selectedUuid: ''
};

class LabOverview extends Component<ILabOverviewProps & ILabOverviewDispatch, ILabOverviewState> {

    static contextType = Auth0Context;

    constructor(props: ILabOverviewProps & ILabOverviewDispatch) {
        super(props);
        this.state = initialState;
        this.onLabSelect = this.onLabSelect.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
    }

    componentDidMount(): void {
        if (this.props.labsUpdateDate && Date.now() - this.props.labsUpdateDate > fetchInterval) {
            this.context.callWithToken((authToken: string) => {
                this.props.loadEthicsApprovals(authToken);
                this.props.loadLabs(authToken)
            })
        }
    }

    onLabSelect(uuid: string) {
        this.setState({
            selectedUuid: uuid
        })
    }

    onClickAdd() {
        this.setState({
            addLab: true
        })
    }

    render() {
        return <div className="top d-flex flex-column align-items-start">
            <div className="d-flex flex-row justify-content-between w-100">
                <h3>
                    Laboratories
                </h3>
                <Button type="button" onClick={this.onClickAdd}>Add laboratory</Button>
            </div>
            <LabTable labs={this.props.labs}
                      ethicsApprovals={this.props.ethicsApprovals}
                      onSelect={this.onLabSelect}/>
            {this.state.addLab && <Redirect to={'lab/new'}/>}
            {this.state.selectedUuid && <Redirect to={`lab/${this.state.selectedUuid}/details`}/>}
        </div>
    }
}

function mapDispatchToProps(dispatch: any): ILabOverviewDispatch {
    return {
        loadEthicsApprovals: (authToken: string) => dispatch(loadEthicsApprovals(authToken)),
        loadLabs: (authToken: string) => dispatch(loadLabs(authToken)),
    }
}

export default connect((state: MainState, ownProps: ILabOverviewProps) => {
    return {
        labs: state.labs,
        ethicsApprovals: state.ethicsApprovals,
        labsUpdateDate: state.labsUpdateDate,
    }
}, mapDispatchToProps)(LabOverview);