import React, { Component } from 'react';
import copy from 'copy-to-clipboard';

import './base.css';

import {Auth0Context} from '../../infrastructure/Auth0/Auth0Provider';
import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface IAuthPageState {
    authToken: string,
    expirationDate?: Date
}

class AuthPage extends Component<{}, IAuthPageState> {

    static contextType = Auth0Context;

    constructor(props: {}) {
        super(props);
        this.state = {
            authToken: "",
        }
    }

    componentDidMount() {
        this.refreshToken();
    }

    copyUserIdToClipboard = () => {
        console.log('Copying auth0 user id to clipboard');
        copy(this.context.user ? this.context.user.sub : '')
    };

    copyAuthTokenToClipboard = () => {
        console.log('Copying auth0 user token to clipboard');
        copy(this.state.authToken)
    };

    copyArgsToClipboard = () => {
        console.log('Copying arguments to clipboard');
        copy(`--user ${this.context.user.sub} --authToken ${this.state.authToken}`)
    };

    refreshToken = () => {
        this.context.callWithToken((authToken: string) => {
            this.setState({
                authToken
            })
        })
    };

    render() {

        return (
            <Form className="p-5 w-100">
                <Form.Group as={Row}>
                    <Form.Label sm={4} column>Auth0 user id</Form.Label>
                    <Col sm={8}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder=""
                                value={this.context.user ? this.context.user.sub : ''}
                                disabled
                            />
                            <InputGroup.Append>
                                <Button
                                    variant="outline-secondary"
                                    onClick={this.copyUserIdToClipboard}
                                >
                                    Copy
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label sm={4} column>Auth0 authentication token</Form.Label>
                    <Col sm={8}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder=""
                                value={this.state.authToken}
                                disabled
                            />
                            <InputGroup.Append>
                                <Button
                                    variant="outline-secondary"
                                    onClick={this.copyAuthTokenToClipboard}
                                >
                                    Copy
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label sm={4} column>Token expiration date</Form.Label>
                    <Col sm={8}>
                        {this.context.user ? new Date(Date.parse(this.context.user.updated_at) + 86400000).toString() : ""}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label sm={4} column>Upload arguments</Form.Label>
                    <Col sm={8}>
                        <InputGroup>
                            <Form.Control type="text"
                                          placeholder={''}
                                          value={`--user "${this.context.user ? this.context.user.sub : ''}" --authToken "${this.state.authToken}"`}
                                          disabled/>
                            <InputGroup.Append>
                                <Button variant="outline-secondary"
                                        onClick={this.copyArgsToClipboard}>
                                    Copy
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Form.Group>
            </Form>
        )
    }
}

export default AuthPage
