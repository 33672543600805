import React, {Component} from 'react';

import './base.css';

import {Lab} from "../../types/data/Lab";
import Table from "react-bootstrap/Table";

interface ILabTableProps {
    labs?: { [uuid: string]: Lab };
    ethicsApprovals?: {[id: string]: string};
    onSelect: (uuid: string) => void;
}

interface ILabTableState {

}

export default class LabTable extends Component<ILabTableProps, ILabTableState> {

    render() {
        let tableRows: any[] = [];
        if (this.props.labs) {
            for (let uuid in this.props.labs) {
                if (this.props.labs.hasOwnProperty(uuid)) {
                    let lab = this.props.labs[uuid];
                    tableRows.push(
                        <tr onClick={(e) => this.props.onSelect(uuid)} key={`lab_${uuid}`}>
                            <th>{lab.uuid}</th>
                            <th>{lab.name}</th>
                            <th>{this.props.ethicsApprovals ? this.props.ethicsApprovals[lab.ethicsApprovalUuid] : lab.ethicsApprovalUuid}</th>
                            <th>{new Date(lab.creationDate).toLocaleDateString()}</th>
                        </tr>)
                }
            }
        }

        return <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th>UUID</th>
                    <th>Name</th>
                    <th>Ethics approval</th>
                    <th>Creation date</th>
                </tr>
            </thead>
            <tbody>
                {tableRows}
            </tbody>
        </Table>
    }

}