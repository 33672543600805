export enum DECISION {
    'UNKNOWN' = 'Unknown',
    'YES' = 'Yes',
    'NO' = 'No'
}

function getDecisionDisplayName(decision: DECISION) {
    switch (decision) {
        case DECISION.UNKNOWN:
            return 'Unknown';
        case DECISION.YES:
            return 'Yes';
        case DECISION.NO:
            return 'No';
    }
    return 'Unknown'
}

export const decisionOptions = Object.values(DECISION).reduce((o, key) => ({ ...o, [key]: getDecisionDisplayName(key)}), {})

export enum PAGE_MODE {
    'VIEW',
    'ADD',
    'SEARCH',
    'EDIT'
}

export enum EDIT_STATUS {
    'ADDED',
    'EDITED',
    'DELETED'
}