import {baseState, MainState} from './MainState';
import {IBaseAction} from '../types/actions/IBaseAction';
import {actionTypes} from "../types/actions/Action";
import {Image} from "../types/data/Image";
import {Annotation} from "../types/data/Annotation";
import {TransferDirection, TransferType} from "../types/data/Transfer";
import {LOCK_STATUS} from "../types/data/EditLock";

export default (state = baseState, action: IBaseAction<any>) => {
    let newState;
    switch (action.type) {
        case actionTypes.ADD_ETHICS_APPROVALS:
            let ethicsApprovals = action.payload.ethicsApprovals;
            console.log('AddEthicsApprovals', ethicsApprovals);
            return state.set(MainState.ETHIC_APPROVALS(), state.ethicsApprovals.merge(ethicsApprovals))
                .set(MainState.ETHICS_APPROVALS_UPDATE_DATE(), action.payload.timestamp);

        case actionTypes.ADD_LABS_SUCCESS:
            let labs = action.payload.labs;
            console.log('AddLabs', labs);
            return state.set(MainState.LABS(), state.labs.merge(labs))
                .set(MainState.LABS_UPDATE_DATE(), action.payload.timestamp);

        case actionTypes.ADD_LABS_ERROR:
            return state;

        case actionTypes.ADD_LAB_REQUEST:
            return state.setIn([MainState.ADDING_ENTRIES(), action.payload.tempId], {
                type: 'lab',
                tempId: action.payload.tempId
            });

        case actionTypes.ADD_LAB_SUCCESS:
            return state.setIn([MainState.LABS(), action.payload.labData.uuid], action.payload.labData)
                .set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.ADD_LAB_ERROR:
            console.log('Error adding lab', action.payload.error);
            //TODO handle error
            return state.set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.EDIT_LAB_REQUEST:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid, 'status'], LOCK_STATUS.RELEASING);

        case actionTypes.EDIT_LAB_SUCCESS:
            return state.setIn([MainState.LABS(), action.payload.labData.uuid], state.labs[action.payload.labData.uuid].merge(action.payload.labData))
                .set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.labData.uuid));

        case actionTypes.EDIT_LAB_ERROR:
            console.log('Error editing lab', action.payload.error);
            //TODO handle error
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid], state.editLocks.without(action.payload.uuid));

        case actionTypes.DELETE_LAB_REQUEST:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid, 'status'], LOCK_STATUS.RELEASING);

        case actionTypes.DELETE_LAB_SUCCESS:
            newState = state.set(MainState.LABS(), state.labs.without(action.payload.uuid))
                .set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.uuid));

            for (let patientUuid in state.patients) {
                if (newState.patients[patientUuid].labUuid === action.payload.uuid) {
                    for (let procedureUuid in newState.procedures[patientUuid]) {
                        for (let imageUuid in newState.images[procedureUuid]) {
                            newState = newState.set(MainState.ANNOTATIONS(), newState.annotations.without(imageUuid));
                        }
                        newState = newState.set(MainState.IMAGES(), newState.images.without(procedureUuid));
                    }
                    newState = newState.set(MainState.PROCEDURES(), newState.procedures.without(patientUuid));
                    newState = newState.set(MainState.PATIENTS(), newState.patients.without(patientUuid));
                }
            }
            return newState;

        case actionTypes.DELETE_LAB_ERROR:
            console.log('Error deleting lab', action.payload.error);
            //TODO handle error
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid], state.editLocks.without(action.payload.uuid));

        case actionTypes.ADD_PATIENTS_SUCCESS:
            let patients = action.payload.patients;
            console.log('AddPatients', patients);
            return state.set(MainState.PATIENTS(), state.patients.merge(patients))
                .set(MainState.PATIENTS_UPDATE_DATE(), action.payload.timestamp);

        case actionTypes.ADD_PATIENTS_ERROR:
            return state;

        case actionTypes.ADD_PATIENT_REQUEST:
            return state.setIn([MainState.ADDING_ENTRIES(), action.payload.tempId], {
                type: 'patient',
                tempId: action.payload.tempId
            });

        case actionTypes.ADD_PATIENT_SUCCESS:
            return state.setIn([MainState.PATIENTS(), action.payload.patientData.uuid], action.payload.patientData)
                .set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.ADD_PATIENT_ERROR:
            console.log('Error adding patient', action.payload.error);
            //TODO handle error
            return state.set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.EDIT_PATIENT_REQUEST:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid, 'status'], LOCK_STATUS.RELEASING);

        case actionTypes.EDIT_PATIENT_SUCCESS:
            return state.setIn([MainState.PATIENTS(), action.payload.patientData.uuid], state.patients[action.payload.patientData.uuid].merge(action.payload.patientData))
                .set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.patientData.uuid));

        case actionTypes.EDIT_PATIENT_ERROR:
            console.log('Error editing patient', action.payload.error);
            //TODO handle error
            return state.set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.uuid));

        case actionTypes.DELETE_PATIENT_REQUEST:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid, 'status'], LOCK_STATUS.RELEASING);

        case actionTypes.DELETE_PATIENT_SUCCESS:
            newState = state.set(MainState.PATIENTS(), state.patients.without(action.payload.uuid))
                .set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.uuid));

            for (let procedureUuid in newState.procedures[action.payload.uuid]) {
                for (let imageUuid in newState.images[procedureUuid]) {
                    newState = newState.set(MainState.ANNOTATIONS(), newState.annotations.without(imageUuid));
                }
                newState = newState.set(MainState.IMAGES(), newState.images.without(procedureUuid));
            }
            newState = newState.set(MainState.PROCEDURES(), newState.procedures.without(action.payload.uuid));
            return newState;

        case actionTypes.DELETE_PATIENT_ERROR:
            console.log('Error deleting patient', action.payload.error);
            //TODO handle error
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid], state.editLocks.without(action.payload.uuid));

        case actionTypes.ADD_PROCEDURES_SUCCESS:
            let procedures = action.payload.procedures;
            console.log('AddProcedures', procedures);
            return state.set(MainState.PROCEDURES(), state.procedures.merge(procedures))
                .set(MainState.PROCEDURES_UPDATE_DATE(), action.payload.timestamp);

        case actionTypes.ADD_PROCEDURES_ERROR:
            return state;

        case actionTypes.ADD_PROCEDURE_REQUEST:
            return state.setIn([MainState.ADDING_ENTRIES(), action.payload.tempId], {
                type: 'procedure',
                tempId: action.payload.tempId
            });

        case actionTypes.ADD_PROCEDURE_SUCCESS:
            return state.setIn([MainState.PROCEDURES(), action.payload.procedureData.patientUuid, action.payload.procedureData.uuid], action.payload.procedureData)
                .set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.ADD_PROCEDURE_ERROR:
            console.log('Error adding procedure', action.payload.error);
            //TODO handle error
            return state.set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.EDIT_PROCEDURE_REQUEST:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid, 'status'], LOCK_STATUS.RELEASING);

        case actionTypes.EDIT_PROCEDURE_SUCCESS:
            return state.setIn([MainState.PROCEDURES(), action.payload.procedureData.patientUuid, action.payload.procedureData.uuid],
                state.procedures[action.payload.procedureData.patientUuid][action.payload.procedureData.uuid].merge(action.payload.procedureData))
                .set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.procedureData.uuid));

        case actionTypes.EDIT_PROCEDURE_ERROR:
            console.log('Error editing procedure', action.payload.error);
            //TODO handle error
            return state.set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.uuid));

        case actionTypes.DELETE_PROCEDURE_REQUEST:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid, 'status'], LOCK_STATUS.RELEASING);

        case actionTypes.DELETE_PROCEDURE_SUCCESS:
            newState = state.setIn([MainState.PROCEDURES(), action.payload.patientUuid], state.procedures[action.payload.patientUuid].without(action.payload.uuid))
                .set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.uuid));

            for (let imageUuid in newState.images[action.payload.uuid]) {
                newState = newState.set(MainState.ANNOTATIONS(), newState.annotations.without(imageUuid));
            }
            newState = newState.set(MainState.IMAGES(), newState.images.without(action.payload.uuid));
            return newState;

        case actionTypes.DELETE_PROCEDURE_ERROR:
            console.log('Error deleting procedure', action.payload.error);
            //TODO handle error
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid], state.editLocks.without(action.payload.uuid));

        case actionTypes.ADD_SCANNERS:
            let scanners = action.payload.scanners;
            console.log('AddScanners', scanners);
            return state.set(MainState.SCANNERS(), state.scanners.merge(scanners))
                .set(MainState.SCANNERS_UPDATE_DATE(), action.payload.timestamp);

        case actionTypes.ADD_IMAGES_SUCCESS:
            let images = action.payload.images;
            for (let procedureUuid in images) {
                if (images.hasOwnProperty(procedureUuid)) {
                    for (let imageUuid in images[procedureUuid]) {
                        if (images[procedureUuid].hasOwnProperty(imageUuid)) {
                            if (images[procedureUuid][imageUuid].fileId) {
                                images[procedureUuid][imageUuid].uploaded = true;
                                images[procedureUuid][imageUuid].uploadProgress = 100;
                            } else {
                                images[procedureUuid][imageUuid].uploaded = false;
                                images[procedureUuid][imageUuid].uploadProgress = -1;
                            }
                        }
                    }
                }
            }
            console.log('AddImages', images);
            return state.set(MainState.IMAGES(), state.images.merge(images))
                .set(MainState.IMAGES_UPDATE_DATE(), action.payload.timestamp);

        case actionTypes.ADD_IMAGES_ERROR:
            //TODO handle error
            return state;

        case actionTypes.ADD_IMAGE_REQUEST:
            return state.setIn([MainState.ADDING_ENTRIES(), action.payload.tempId], {
                type: 'image',
                tempId: action.payload.tempId
            });

        case actionTypes.ADD_IMAGE_SUCCESS:
            let image: Image = {
                ...action.payload.imageData,
                uploaded: false,
                uploadProgress: 0
            };

            return state.setIn([MainState.IMAGES(), image.procedureUuid, image.uuid], image)
                .set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.ADD_IMAGE_ERROR:
            console.log('Error adding image', action.payload.error);
            //TODO handle error
            return state.set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.EDIT_IMAGE_REQUEST:
            //TODO rethink if necessary
            return state;

        case actionTypes.EDIT_IMAGE_SUCCESS:
            return state.setIn([MainState.IMAGES(), action.payload.imageData.procedureUuid, action.payload.imageData.uuid],
                state.images[action.payload.imageData.procedureUuid][action.payload.imageData.uuid].merge(action.payload.imageData))

        case actionTypes.EDIT_IMAGE_ERROR:
            console.log('Error editing image', action.payload.error);
            //TODO handle error
            return state;

        case actionTypes.DELETE_IMAGE_REQUEST:
            //TODO rethink if necessary
            return state;

        case actionTypes.DELETE_IMAGE_SUCCESS:
            newState = state.setIn([MainState.IMAGES(), action.payload.procedureUuid], state.images[action.payload.procedureUuid].without(action.payload.uuid))
                .setIn([MainState.EDIT_LOCKS(), action.payload.uuid], state.editLocks.without(action.payload.uuid));

            for (let imageUuid in newState.images[action.payload.uuid]) {
                newState = newState.set(MainState.ANNOTATIONS(), newState.annotations.without(imageUuid));
            }
            return newState;

        case actionTypes.DELETE_IMAGE_ERROR:
            console.log('Error deleting image', action.payload.error);
            //TODO handle error
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid], state.editLocks.without(action.payload.uuid));

        case actionTypes.UPLOAD_IMAGE_REQUEST:
            return state.setIn([MainState.TRANSFERS(), action.payload.uuid], {
                ids: {
                    uuid: action.payload.uuid,
                    procedureUuid: action.payload.procedureUuid
                },
                type: TransferType.IMAGE,
                displayName: action.payload.name,
                direction: TransferDirection.UPLOAD,
                progress: 0,
                startTime: Date.now()
            }).set(MainState.TRANSFERS_HIDDEN(), 0);

        case actionTypes.UPLOAD_IMAGE_PROGRESS:
            return state.setIn([MainState.IMAGES(), action.payload.procedureUuid, action.payload.uuid, 'uploadProgress'], action.payload.progress)
                .setIn([MainState.TRANSFERS(), action.payload.uuid, 'progress'], action.payload.progress);

        case actionTypes.UPLOAD_IMAGE_SUCCESS:
            return state.setIn([MainState.IMAGES(), action.payload.procedureUuid, action.payload.uuid, 'uploaded'], true)
                .setIn([MainState.IMAGES(), action.payload.procedureUuid, action.payload.uuid, 'uploadProgress'], 100)
                .setIn([MainState.IMAGES(), action.payload.procedureUuid, action.payload.uuid, 'fileId'], action.payload.fileId)
                .setIn([MainState.IMAGES(), action.payload.procedureUuid, action.payload.uuid, 'fileType'], action.payload.fileType)
                .set(MainState.TRANSFERS(), state.transfers.without(action.payload.uuid));

        case actionTypes.UPLOAD_IMAGE_ERROR:
            console.log('Error uploading image', action.payload.uuid, action.payload.error);
            return state.setIn([MainState.IMAGES(), action.payload.procedureUuid, action.payload.uuid, 'progress'], -1)
                .set(MainState.TRANSFERS(), state.transfers.without(action.payload.uuid));

        case actionTypes.ADD_ANNOTATIONS_SUCCESS:
            let annotations = action.payload.annotations;
            for (let procedureUuid in annotations) {
                if (annotations.hasOwnProperty(procedureUuid)) {
                    for (let annotationUuid in annotations[procedureUuid]) {
                        if (annotations[procedureUuid].hasOwnProperty(annotationUuid)) {
                            if (annotations[procedureUuid][annotationUuid].fileId) {
                                annotations[procedureUuid][annotationUuid].uploaded = true;
                                annotations[procedureUuid][annotationUuid].uploadProgress = 100;
                            } else {
                                annotations[procedureUuid][annotationUuid].uploaded = false;
                                annotations[procedureUuid][annotationUuid].uploadProgress = -1;
                            }
                            if (!annotations[procedureUuid][annotationUuid].modeUuid) {
                                annotations[procedureUuid][annotationUuid].modeUuid = 'Unknown';
                            }
                        }
                    }
                }
            }
            console.log('AddAnnotations', annotations);
            return state.set(MainState.ANNOTATIONS(), state.annotations.merge(annotations))
                .set(MainState.ANNOTATIONS_UPDATE_DATE(), action.payload.timestamp);

        case actionTypes.ADD_ANNOTATIONS_ERROR:
            //TODO handle error
            return state;

        case actionTypes.ADD_ANNOTATION_TYPES:
            let annotationTypes = action.payload.annotationTypes;
            console.log('AddAnnotationTypes', annotationTypes);
            return state.set(MainState.ANNOTATION_TYPES(), state.annotationTypes.merge(annotationTypes))
                .set(MainState.ANNOTATION_TYPES_UPDATE_DATE(), action.payload.timestamp);

        case actionTypes.ADD_ANNOTATION_REQUEST:
            return state.setIn([MainState.ADDING_ENTRIES(), action.payload.tempId], {
                type: 'annotation',
                tempId: action.payload.tempId
            });

        case actionTypes.ADD_ANNOTATION_SUCCESS:
            let annotation: Annotation = {
                ...action.payload.annotationData,
                uploaded: false,
                uploadProgress: 0
            };

            return state.setIn([MainState.ANNOTATIONS(), annotation.imageUuid, annotation.uuid], annotation)
                .set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.ADD_ANNOTATION_ERROR:
            console.log('Error adding annotation', action.payload.error);
            //TODO handle error
            return state.set(MainState.ADDING_ENTRIES(), state.addingEntries.without(action.payload.tempId));

        case actionTypes.EDIT_ANNOTATION_REQUEST:
            //TODO rethink if necessary
            return state;

        case actionTypes.EDIT_ANNOTATION_SUCCESS:
            return state.setIn([MainState.ANNOTATIONS(), action.payload.annotationData.imageUuid, action.payload.annotationData.uuid],
                state.annotations[action.payload.annotationData.imageUuid][action.payload.annotationData.uuid].merge(action.payload.annotationData));

        case actionTypes.EDIT_ANNOTATION_ERROR:
            console.log('Error editing annotation', action.payload.error);
            //TODO handle error
            return state;

        case actionTypes.DELETE_ANNOTATION_REQUEST:
            //TODO rethink if necessary
            return state;

        case actionTypes.DELETE_ANNOTATION_SUCCESS:
            return state.setIn([MainState.ANNOTATIONS(), action.payload.imageUuid], state.annotations[action.payload.imageUuid].without(action.payload.uuid));

        case actionTypes.DELETE_ANNOTATION_ERROR:
            console.log('Error deleting annotation', action.payload.error);
            //TODO handle error
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid], state.editLocks.without(action.payload.uuid));

        case actionTypes.UPLOAD_ANNOTATION_REQUEST:
            return state.setIn([MainState.TRANSFERS(), action.payload.uuid], {
                ids: {
                    uuid: action.payload.uuid,
                    imageUuid: action.payload.imageUuid
                },
                type: TransferType.ANNOTATION,
                displayName: action.payload.name,
                direction: TransferDirection.UPLOAD,
                progress: 0,
                startTime: Date.now()
            }).set(MainState.TRANSFERS_HIDDEN(), 0);

        case actionTypes.UPLOAD_ANNOTATION_PROGRESS:
            return state.setIn([MainState.ANNOTATIONS(), action.payload.imageUuid, action.payload.uuid, 'uploadProgress'], action.payload.progress)
                .setIn([MainState.TRANSFERS(), action.payload.uuid, 'progress'], action.payload.progress);

        case actionTypes.UPLOAD_ANNOTATION_SUCCESS:
            return state.setIn([MainState.ANNOTATIONS(), action.payload.imageUuid, action.payload.uuid, 'uploaded'], true)
                .setIn([MainState.ANNOTATIONS(), action.payload.imageUuid, action.payload.uuid, 'uploadProgress'], 100)
                .setIn([MainState.ANNOTATIONS(), action.payload.imageUuid, action.payload.uuid, 'fileId'], action.payload.fileId)
                .set(MainState.TRANSFERS(), state.transfers.without(action.payload.uuid));

        case actionTypes.UPLOAD_ANNOTATION_ERROR:
            return state.setIn([MainState.ANNOTATIONS(), action.payload.imageUuid, action.payload.uuid, 'uploadProgress'], -1)
                .set(MainState.TRANSFERS(), state.transfers.without(action.payload.uuid));

        case actionTypes.DOWNLOAD_IMAGE_REQUEST:
            return state.setIn([MainState.TRANSFERS(), action.payload.uuid], {
                ids: {uuid: action.payload.uuid, procedureUuid: action.payload.procedureUuid},
                displayName: state.images[action.payload.procedureUuid][action.payload.uuid].name,
                type: TransferType.IMAGE,
                direction: TransferDirection.DOWNLOAD,
                progress: 0,
                startTime: Date.now()
            }).set(MainState.TRANSFERS_HIDDEN(), 0);

        case actionTypes.DOWNLOAD_IMAGE_PROGRESS:
            return state.setIn([MainState.TRANSFERS(), action.payload.uuid, 'progress'], action.payload.progress);

        case actionTypes.DOWNLOAD_IMAGE_SUCCESS:
            return state.set(MainState.TRANSFERS(), state.transfers.without(action.payload.uuid));

        case actionTypes.DOWNLOAD_IMAGE_ERROR:
            return state.set(MainState.TRANSFERS(), state.transfers.without(action.payload.uuid));

        case actionTypes.DOWNLOAD_ANNOTATION:
            return state.setIn([MainState.TRANSFERS(), action.payload.uuid], {
                ids: {uuid: action.payload.uuid, imageUuid: action.payload.imageUuid},
                displayName: state.annotations[action.payload.imageUuid][action.payload.uuid].name,
                type: TransferType.ANNOTATION,
                direction: TransferDirection.DOWNLOAD,
                progress: 0,
                startTime: Date.now()
            }).set(MainState.TRANSFERS_HIDDEN(), 0);

        case actionTypes.DOWNLOAD_ANNOTATION_PROGRESS:
            return state.setIn([MainState.TRANSFERS(), action.payload.uuid, 'progress'], action.payload.progress);

        case actionTypes.DOWNLOAD_ANNOTATION_SUCCESS:
            return state.set(MainState.TRANSFERS(), state.transfers.without(action.payload.uuid));

        case actionTypes.DOWNLOAD_ANNOTATION_ERROR:
            return state.set(MainState.TRANSFERS(), state.transfers.without(action.payload.uuid));

        case actionTypes.HIDE_TRANSFERS:
            return state.set(MainState.TRANSFERS_HIDDEN(), action.payload.timestamp)

        case actionTypes.ACQUIRE_EDIT_LOCK_REQUEST:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid], {
                uuid: action.payload.uuid,
                type: action.payload.table,
                status: LOCK_STATUS.REQUESTED
            });

        case actionTypes.ACQUIRE_EDIT_LOCK_SUCCESS:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid], state.editLocks[action.payload.uuid].merge(
                {
                    status: action.payload.lockDate > 0 ? LOCK_STATUS.ACQUIRED : LOCK_STATUS.UNAVAILABLE,
                    lockDate: action.payload.lockDate
                }
            ));

        case actionTypes.ACQUIRE_EDIT_LOCK_ERROR:
            console.log('Error acquiring editLock', action.payload.error);
            //TODO handle error
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid, 'status'], LOCK_STATUS.ERROR);

        case actionTypes.RELEASE_EDIT_LOCK_REQUEST:
            return state.setIn([MainState.EDIT_LOCKS(), action.payload.uuid, 'status'], LOCK_STATUS.RELEASING);

        case actionTypes.RELEASE_EDIT_LOCK_SUCCESS:
            return state.set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.uuid));

        case actionTypes.RELEASE_EDIT_LOCK_ERROR:
            console.log('Error releasing editLock', action.payload.error);
            //TODO handle error
            return state.set(MainState.EDIT_LOCKS(), state.editLocks.without(action.payload.uuid));

        case actionTypes.ADD_MANAGED_INSTANCES:
            console.log('Adding managed instances')
            return state.set(MainState.MANAGED_INSTANCES(), action.payload.instances);

        case actionTypes.REGISTER_TO_INSTANCE:
            console.log('Registering to instance', action.payload.instanceName, state.getIn([MainState.MANAGED_INSTANCES(), action.payload.instanceName, 'registeredUsers']), [MainState.MANAGED_INSTANCES(), action.payload.instanceName, 'registeredUsers', action.payload.authId])
            return state.setIn([MainState.MANAGED_INSTANCES(), action.payload.instanceName, 'registeredUsers', action.payload.authId], action.payload.authName);

        case actionTypes.DEREGISTER_FROM_INSTANCE:
            console.log('Deregistering from instance', action.payload.instanceName);
            return state.setIn([MainState.MANAGED_INSTANCES(), action.payload.instanceName, 'registeredUsers'],
                state.getIn([MainState.MANAGED_INSTANCES(), action.payload.instanceName, 'registeredUsers']).without(action.payload.authId));

        default:
            return state;

    }
}
