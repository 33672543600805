export enum actionTypes {
    'ADD_ETHICS_APPROVALS' = 'ADD_ETHICS_APPROVALS',
    'ADD_LABS_SUCCESS' = 'ADD_LABS_SUCCESS',
    'ADD_LABS_ERROR' = 'ADD_LABS_ERROR',
    'ADD_LAB_REQUEST' = 'ADD_LAB_REQUEST',
    'ADD_LAB_SUCCESS' = 'ADD_LAB_SUCCESS',
    'ADD_LAB_ERROR' = 'ADD_LAB_ERROR',
    'EDIT_LAB_REQUEST' = 'EDIT_LAB_REQUEST',
    'EDIT_LAB_SUCCESS' = 'EDIT_LAB_SUCCESS',
    'EDIT_LAB_ERROR' = 'EDIT_LAB_ERROR',
    'DELETE_LAB_REQUEST' = 'DELETE_LAB_REQUEST',
    'DELETE_LAB_SUCCESS' = 'DELETE_LAB_SUCCESS',
    'DELETE_LAB_ERROR' = 'DELETE_LAB_ERROR',
    'ADD_PATIENTS_SUCCESS' = 'ADD_PATIENTS_SUCCESS',
    'ADD_PATIENTS_ERROR' = 'ADD_PATIENTS_ERROR',
    'ADD_PATIENT_REQUEST' = 'ADD_PATIENT_REQUEST',
    'ADD_PATIENT_SUCCESS' = 'ADD_PATIENT_SUCCESS',
    'ADD_PATIENT_ERROR' = 'ADD_PATIENT_ERROR',
    'EDIT_PATIENT_REQUEST' = 'EDIT_PATIENT_REQUEST',
    'EDIT_PATIENT_SUCCESS' = 'EDIT_PATIENT_SUCCESS',
    'EDIT_PATIENT_ERROR' = 'EDIT_PATIENT_ERROR',
    'DELETE_PATIENT_REQUEST' = 'DELETE_PATIENT_REQUEST',
    'DELETE_PATIENT_SUCCESS' = 'DELETE_PATIENT_SUCCESS',
    'DELETE_PATIENT_ERROR' = 'DELETE_PATIENT_ERROR',
    'ADD_SCANNERS' = 'ADD_SCANNERS',
    'ADD_PROCEDURES_SUCCESS' = 'ADD_PROCEDURES_SUCCESS',
    'ADD_PROCEDURES_ERROR' = 'ADD_PROCEDURES_ERROR',
    'ADD_PROCEDURE_REQUEST' = 'ADD_PROCEDURE_REQUEST',
    'ADD_PROCEDURE_SUCCESS' = 'ADD_PROCEDURE_SUCCESS',
    'ADD_PROCEDURE_ERROR' = 'ADD_PROCEDURE_ERROR',
    'EDIT_PROCEDURE_REQUEST' = 'EDIT_PROCEDURE_REQUEST',
    'EDIT_PROCEDURE_SUCCESS' = 'EDIT_PROCEDURE_SUCCESS',
    'EDIT_PROCEDURE_ERROR' = 'EDIT_PROCEDURE_ERROR',
    'DELETE_PROCEDURE_REQUEST' = 'DELETE_PROCEDURE_REQUEST',
    'DELETE_PROCEDURE_SUCCESS' = 'DELETE_PROCEDURE_SUCCESS',
    'DELETE_PROCEDURE_ERROR' = 'DELETE_PROCEDURE_ERROR',
    'ADD_IMAGES_SUCCESS' = 'ADD_IMAGES_SUCCESS',
    'ADD_IMAGES_ERROR' = 'ADD_IMAGES_ERROR',
    'ADD_IMAGE_REQUEST' = 'ADD_IMAGE_REQUEST',
    'ADD_IMAGE_SUCCESS' = 'ADD_IMAGE_SUCCESS',
    'ADD_IMAGE_ERROR' = 'ADD_IMAGE_ERROR',
    'EDIT_IMAGE_REQUEST' = 'EDIT_IMAGE_REQUEST',
    'EDIT_IMAGE_SUCCESS' = 'EDIT_IMAGE_SUCCESS',
    'EDIT_IMAGE_ERROR' = 'EDIT_IMAGE_ERROR',
    'DELETE_IMAGE_REQUEST' = 'DELETE_IMAGE_REQUEST',
    'DELETE_IMAGE_SUCCESS' = 'DELETE_IMAGE_SUCCESS',
    'DELETE_IMAGE_ERROR' = 'DELETE_IMAGE_ERROR',
    'UPLOAD_IMAGE_REQUEST' = 'UPLOAD_IMAGE_REQUEST',
    'UPLOAD_IMAGE_PROGRESS' = 'UPLOAD_IMAGE_PROGRESS',
    'UPLOAD_IMAGE_SUCCESS' = 'UPLOAD_IMAGE_SUCCESS',
    'UPLOAD_IMAGE_ERROR' = 'UPLOAD_IMAGE_ERROR',
    'ADD_ANNOTATION_TYPES' = 'ADD_ANNOTATION_TYPES',
    'ADD_ANNOTATIONS_SUCCESS' = 'ADD_ANNOTATIONS_SUCCESS',
    'ADD_ANNOTATIONS_ERROR' = 'ADD_ANNOTATIONS_ERROR',
    'ADD_ANNOTATION_REQUEST' = 'ADD_ANNOTATION_REQUEST',
    'ADD_ANNOTATION_SUCCESS' = 'ADD_ANNOTATION_SUCCESS',
    'ADD_ANNOTATION_ERROR' = 'ADD_ANNOTATION_ERROR',
    'EDIT_ANNOTATION_REQUEST' = 'EDIT_ANNOTATION_REQUEST',
    'EDIT_ANNOTATION_SUCCESS' = 'EDIT_ANNOTATION_SUCCESS',
    'EDIT_ANNOTATION_ERROR' = 'EDIT_ANNOTATION_ERROR',
    'DELETE_ANNOTATION_REQUEST' = 'DELETE_ANNOTATION_REQUEST',
    'DELETE_ANNOTATION_SUCCESS' = 'DELETE_ANNOTATION_SUCCESS',
    'DELETE_ANNOTATION_ERROR' = 'DELETE_ANNOTATION_ERROR',
    'UPLOAD_ANNOTATION_REQUEST' = 'UPLOAD_ANNOTATION_REQUEST',
    'UPLOAD_ANNOTATION_PROGRESS' = 'UPLOAD_ANNOTATION_PROGRESS',
    'UPLOAD_ANNOTATION_SUCCESS' = 'UPLOAD_ANNOTATION_SUCCESS',
    'UPLOAD_ANNOTATION_ERROR' = 'UPLOAD_ANNOTATION_ERROR',
    'DOWNLOAD_IMAGE_REQUEST' = 'DOWNLOAD_IMAGE_REQUEST',
    'DOWNLOAD_IMAGE_PROGRESS' = 'DOWNLOAD_IMAGE_PROGRESS',
    'DOWNLOAD_IMAGE_SUCCESS' = 'DOWNLOAD_IMAGE_SUCCESS',
    'DOWNLOAD_IMAGE_ERROR' = 'DOWNLOAD_IMAGE_ERROR',
    'DOWNLOAD_ANNOTATION' = 'DOWNLOAD_ANNOTATION',
    'DOWNLOAD_ANNOTATION_PROGRESS' = 'DOWNLOAD_ANNOTATION_PROGRESS',
    'DOWNLOAD_ANNOTATION_SUCCESS' = 'DOWNLOAD_ANNOTATION_SUCCESS',
    'DOWNLOAD_ANNOTATION_ERROR' = 'DOWNLOAD_ANNOTATION_ERROR',
    'HIDE_TRANSFERS' = 'HIDE_TRANSFERS',
    'ACQUIRE_EDIT_LOCK_REQUEST' = 'ACQUIRE_EDIT_LOCK_REQUEST',
    'ACQUIRE_EDIT_LOCK_SUCCESS' = 'ACQUIRE_EDIT_LOCK_SUCCESS',
    'ACQUIRE_EDIT_LOCK_ERROR' = 'ACQUIRE_EDIT_LOCK_ERROR',
    'RELEASE_EDIT_LOCK_REQUEST' = 'RELEASE_EDIT_LOCK_REQUEST',
    'RELEASE_EDIT_LOCK_SUCCESS' = 'RELEASE_EDIT_LOCK_SUCCESS',
    'RELEASE_EDIT_LOCK_ERROR' = 'RELEASE_EDIT_LOCK_ERROR',
    'ADD_MANAGED_INSTANCES' = 'ADD_MANAGED_INSTANCES',
    'REGISTER_TO_INSTANCE' = 'REGISTER_TO_INSTANCE',
    'DEREGISTER_FROM_INSTANCE' = 'DEREGISTER_FROM_INSTANCE',
}
