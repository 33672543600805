import {actionTypes} from "./Action";
import {Lab} from "../data/Lab";
import {Patient} from "../data/Patient";
import {Procedure} from "../data/Procedure";
import Image from "react-bootstrap/Image";
import {Scanner} from "../data/Scanner";
import {Annotation, AnnotationMode} from "../data/Annotation";

export function addEthicsApprovals(timestamp: number, ethicsApprovals: { [ethicsApprovalUuid: string]: String }) {
    return {
        type: actionTypes.ADD_ETHICS_APPROVALS,
        payload: {
            timestamp,
            ethicsApprovals
        }
    }
}

export function addLabsSuccess(timestamp: number, labs: { [labUuid: string]: Lab }) {
    return {
        type: actionTypes.ADD_LABS_SUCCESS,
        payload: {
            timestamp,
            labs
        }
    }
}

export function addLabsError(error: any) {
    return {
        type: actionTypes.ADD_LABS_ERROR,
        payload: {
            error
        }
    }
}

export function addLabRequest(tempId: string) {
    return {
        type: actionTypes.ADD_LAB_REQUEST,
        payload: {
            tempId
        }
    }
}

export function addLabSuccess(tempId: string, labData: any) {
    return {
        type: actionTypes.ADD_LAB_SUCCESS,
        payload: {
            labData,
            tempId
        }
    }
}

export function addLabError(tempId: string, error: any) {
    return {
        type: actionTypes.ADD_LAB_ERROR,
        payload: {
            error,
            tempId
        }
    }
}

export function editLabRequest(uuid: string) {
    return {
        type: actionTypes.EDIT_LAB_REQUEST,
        payload: {
            uuid
        }
    }
}

export function editLabSuccess(labData: any) {
    return {
        type: actionTypes.EDIT_LAB_SUCCESS,
        payload: {
            labData
        }
    }
}

export function editLabError(uuid: string, error: any) {
    return {
        type: actionTypes.EDIT_LAB_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function deleteLabRequest(uuid: string) {
    return {
        type: actionTypes.DELETE_LAB_REQUEST,
        payload: {
            uuid
        }
    }
}

export function deleteLabSuccess(uuid: string) {
    return {
        type: actionTypes.DELETE_LAB_SUCCESS,
        payload: {
            uuid
        }
    }
}

export function deleteLabError(uuid: string, error: any) {
    return {
        type: actionTypes.DELETE_LAB_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function addPatientsSuccess(timestamp: number, patients: { [patientUuid: string]: Patient }) {
    return {
        type: actionTypes.ADD_PATIENTS_SUCCESS,
        payload: {
            timestamp,
            patients
        }
    }
}

export function addPatientsError(error: any) {
    return {
        type: actionTypes.ADD_PATIENTS_ERROR,
        payload: {
            error
        }
    }
}

export function addPatientRequest(tempId: string) {
    return {
        type: actionTypes.ADD_PATIENT_REQUEST,
        payload: {
            tempId
        }
    }
}

export function addPatientSuccess(tempId: string, patientData: any) {
    return {
        type: actionTypes.ADD_PATIENT_SUCCESS,
        payload: {
            patientData,
            tempId
        }
    }
}

export function addPatientError(tempId: string, error: any) {
    return {
        type: actionTypes.ADD_PATIENT_ERROR,
        payload: {
            error,
            tempId
        }
    }
}

export function editPatientRequest(uuid: string) {
    return {
        type: actionTypes.EDIT_PATIENT_REQUEST,
        payload: {
            uuid
        }
    }
}

export function editPatientSuccess(patientData: any) {
    return {
        type: actionTypes.EDIT_PATIENT_SUCCESS,
        payload: {
            patientData
        }
    }
}

export function editPatientError(uuid: string, error: any) {
    return {
        type: actionTypes.EDIT_PATIENT_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function deletePatientRequest(uuid: string) {
    return {
        type: actionTypes.DELETE_PATIENT_REQUEST,
        payload: {
            uuid
        }
    }
}

export function deletePatientSuccess(uuid: string) {
    return {
        type: actionTypes.DELETE_PATIENT_SUCCESS,
        payload: {
            uuid
        }
    }
}

export function deletePatientError(uuid: string, error: any) {
    return {
        type: actionTypes.DELETE_PATIENT_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function addProceduresSuccess(timestamp: number, procedures: { [patientUuid: string]: { [procedureUuid: string]: Procedure } }) {
    return {
        type: actionTypes.ADD_PROCEDURES_SUCCESS,
        payload: {
            timestamp,
            procedures
        }
    }
}

export function addProceduresError(error: any) {
    return {
        type: actionTypes.ADD_PROCEDURES_ERROR,
        payload: {
            error
        }
    }
}

export function addProcedureRequest(tempId: string) {
    return {
        type: actionTypes.ADD_PROCEDURE_REQUEST,
        payload: {
            tempId
        }
    }
}

export function addProcedureSuccess(tempId: string, procedureData: any) {
    return {
        type: actionTypes.ADD_PROCEDURE_SUCCESS,
        payload: {
            procedureData,
            tempId
        }
    }
}

export function addProcedureError(tempId: string, error: any) {
    return {
        type: actionTypes.ADD_PROCEDURE_ERROR,
        payload: {
            error,
            tempId
        }
    }
}

export function editProcedureRequest(uuid: string) {
    return {
        type: actionTypes.EDIT_PROCEDURE_REQUEST,
        payload: {
            uuid
        }
    }
}

export function editProcedureSuccess(procedureData: any) {
    return {
        type: actionTypes.EDIT_PROCEDURE_SUCCESS,
        payload: {
            procedureData
        }
    }
}

export function editProcedureError(uuid: string, error: any) {
    return {
        type: actionTypes.EDIT_PROCEDURE_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function deleteProcedureRequest(uuid: string) {
    return {
        type: actionTypes.DELETE_PROCEDURE_REQUEST,
        payload: {
            uuid
        }
    }
}

export function deleteProcedureSuccess(patientUuid: string, uuid: string) {
    return {
        type: actionTypes.DELETE_PROCEDURE_SUCCESS,
        payload: {
            patientUuid,
            uuid
        }
    }
}

export function deleteProcedureError(uuid: string, error: any) {
    return {
        type: actionTypes.DELETE_PROCEDURE_ERROR,
        payload: {
            uuid,
            error
        }
    }
}


export function addScanners(timestamp: number, scanners: { [uuid: string]: Scanner}) {
    return {
        type: actionTypes.ADD_SCANNERS,
        payload: {
            timestamp,
            scanners
        }
    }
}

export function addImagesSuccess(timestamp: number, images: { [procedureUuid: string]: { [imageUuid: string]: Image } }) {
    return {
        type: actionTypes.ADD_IMAGES_SUCCESS,
        payload: {
            timestamp,
            images
        }
    }
}

export function addImagesError(error: any) {
    return {
        type: actionTypes.ADD_IMAGES_ERROR,
        payload: {
            error
        }
    }
}

export function addImageRequest(tempId: string) {
    return {
        type: actionTypes.ADD_IMAGE_REQUEST,
        payload: {
            tempId
        }
    }
}

export function addImageSuccess(tempId: string, imageData: any) {
    return {
        type: actionTypes.ADD_IMAGE_SUCCESS,
        payload: {
            imageData,
            tempId
        }
    }
}

export function addImageError(tempId: string, error: any) {
    return {
        type: actionTypes.ADD_IMAGE_ERROR,
        payload: {
            error,
            tempId
        }
    }
}

export function editImageRequest(uuid: string) {
    return {
        type: actionTypes.EDIT_IMAGE_REQUEST,
        payload: {
            uuid
        }
    }
}

export function editImageSuccess(imageData: any) {
    return {
        type: actionTypes.EDIT_IMAGE_SUCCESS,
        payload: {
            imageData
        }
    }
}

export function editImageError(uuid: string, error: any) {
    return {
        type: actionTypes.EDIT_IMAGE_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function deleteImageRequest(uuid: string) {
    return {
        type: actionTypes.DELETE_IMAGE_REQUEST,
        payload: {
            uuid
        }
    }
}

export function deleteImageSuccess(procedureUuid: string, uuid: string) {
    return {
        type: actionTypes.DELETE_IMAGE_SUCCESS,
        payload: {
            procedureUuid,
            uuid
        }
    }
}

export function deleteImageError(uuid: string, error: any) {
    return {
        type: actionTypes.DELETE_IMAGE_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function uploadImageRequest(uuid: string, procedureUuid: string, name: string) {
    return {
        type: actionTypes.UPLOAD_IMAGE_REQUEST,
        payload: {
            uuid,
            procedureUuid,
            name
        }
    }
}

export function uploadImageProgress(uuid: string, procedureUuid: string, progress: number) {
    return {
        type: actionTypes.UPLOAD_IMAGE_PROGRESS,
        payload: {
            uuid,
            procedureUuid,
            progress
        }
    }
}

export function uploadImageSuccess(uuid: string, procedureUuid: string, fileId: string, fileType: string) {
    return {
        type: actionTypes.UPLOAD_IMAGE_SUCCESS,
        payload: {
            uuid,
            procedureUuid,
            fileId,
            fileType
        }
    }
}

export function uploadImageError(uuid: string, procedureUuid: string, error: any) {
    return {
        type: actionTypes.UPLOAD_IMAGE_ERROR,
        payload: {
            uuid,
            procedureUuid,
            error
        }
    }
}

export function addAnnotationsSuccess(timestamp: number, annotations: { [imageUuid: string]: { [uuid: string]: Annotation} } ) {
    return {
        type: actionTypes.ADD_ANNOTATIONS_SUCCESS,
        payload: {
            timestamp,
            annotations
        }
    }
}

export function addAnnotationsError(annotations: { [imageUuid: string]: { [uuid: string]: Annotation} } ) {
    return {
        type: actionTypes.ADD_ANNOTATIONS_ERROR,
        payload: {
            annotations
        }
    }
}

export function addAnnotationTypes(timestamp: number, annotationTypes: { [uuid: string]: AnnotationMode} ) {
    return {
        type: actionTypes.ADD_ANNOTATION_TYPES,
        payload: {
            timestamp,
            annotationTypes
        }
    }
}

export function addAnnotationRequest(tempId: string) {
    return {
        type: actionTypes.ADD_ANNOTATION_REQUEST,
        payload: {
            tempId
        }
    }
}

export function addAnnotationSuccess(tempId: string, annotationData: any) {
    return {
        type: actionTypes.ADD_ANNOTATION_SUCCESS,
        payload: {
            annotationData,
            tempId
        }
    }
}

export function addAnnotationError(tempId: string, error: any) {
    return {
        type: actionTypes.ADD_ANNOTATION_ERROR,
        payload: {
            error,
            tempId
        }
    }
}

export function editAnnotationRequest(uuid: string) {
    return {
        type: actionTypes.EDIT_ANNOTATION_REQUEST,
        payload: {
            uuid
        }
    }
}

export function editAnnotationSuccess(annotationData: any) {
    return {
        type: actionTypes.EDIT_ANNOTATION_SUCCESS,
        payload: {
            annotationData
        }
    }
}

export function editAnnotationError(uuid: string, error: any) {
    return {
        type: actionTypes.EDIT_ANNOTATION_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function deleteAnnotationRequest(uuid: string) {
    return {
        type: actionTypes.DELETE_ANNOTATION_REQUEST,
        payload: {
            uuid
        }
    }
}

export function deleteAnnotationSuccess(imageUuid: string, uuid: string) {
    return {
        type: actionTypes.DELETE_ANNOTATION_SUCCESS,
        payload: {
            uuid,
            imageUuid
        }
    }
}

export function deleteAnnotationError(uuid: string, error: any) {
    return {
        type: actionTypes.DELETE_ANNOTATION_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function uploadAnnotationRequest(uuid: string, imageUuid: string, name: string) {
    return {
        type: actionTypes.UPLOAD_ANNOTATION_REQUEST,
        payload: {
            uuid,
            imageUuid,
            name
        }
    }
}

export function uploadAnnotationProgress(uuid: string, imageUuid: string, progress: number) {
    return {
        type: actionTypes.UPLOAD_ANNOTATION_PROGRESS,
        payload: {
            uuid,
            imageUuid,
            progress
        }
    }
}

export function uploadAnnotationSuccess(uuid: string, imageUuid: string, fileId: string) {
    return {
        type: actionTypes.UPLOAD_ANNOTATION_SUCCESS,
        payload: {
            uuid,
            imageUuid,
            fileId
        }
    }
}

export function uploadAnnotationError(uuid: string, imageUuid: string, error: any) {
    return {
        type: actionTypes.UPLOAD_ANNOTATION_ERROR,
        payload: {
            uuid,
            imageUuid,
            error
        }
    }
}

export function downloadImageRequest(uuid: string, procedureUuid: string) {
    return {
        type: actionTypes.DOWNLOAD_IMAGE_REQUEST,
        payload: {
            uuid,
            procedureUuid
        }
    }
}

export function downloadImageProgress(uuid: string, procedureUuid: string, progress: number) {
    return {
        type: actionTypes.DOWNLOAD_IMAGE_PROGRESS,
        payload: {
            uuid,
            procedureUuid,
            progress
        }
    }
}

export function downloadImageSuccess(uuid: string, procedureUuid: string) {
    return {
        type: actionTypes.DOWNLOAD_IMAGE_SUCCESS,
        payload: {
            uuid,
            procedureUuid
        }
    }
}

export function downloadImageError(uuid: string, procedureUuid: string, error: any) {
    return {
        type: actionTypes.DOWNLOAD_IMAGE_ERROR,
        payload: {
            uuid,
            procedureUuid,
            error
        }
    }
}

export function downloadAnnotation(uuid: string, imageUuid: string) {
    return {
        type: actionTypes.DOWNLOAD_ANNOTATION,
        payload: {
            uuid,
            imageUuid
        }
    }
}

export function downloadAnnotationProgress(uuid: string, imageUuid: string, progress: number) {
    return {
        type: actionTypes.DOWNLOAD_ANNOTATION_PROGRESS,
        payload: {
            uuid,
            imageUuid,
            progress
        }
    }
}

export function downloadAnnotationSuccess(uuid: string, imageUuid: string) {
    return {
        type: actionTypes.DOWNLOAD_ANNOTATION_SUCCESS,
        payload: {
            uuid,
            imageUuid
        }
    }
}

export function downloadAnnotationError(uuid: string, imageUuid: string, error: any) {
    return {
        type: actionTypes.DOWNLOAD_ANNOTATION_ERROR,
        payload: {
            uuid,
            imageUuid,
            error
        }
    }
}

export function hideTransfers() {
    return {
        type: actionTypes.HIDE_TRANSFERS,
        payload: {
            timestamp: Date.now()
        }
    }
}

export function acquireEditLockRequest(table: string, uuid: string) {
    return {
        type: actionTypes.ACQUIRE_EDIT_LOCK_REQUEST,
        payload: {
            table,
            uuid
        }
    }
}

export function acquireEditLockSuccess(uuid: string, lockDate: number) {
    return {
        type: actionTypes.ACQUIRE_EDIT_LOCK_SUCCESS,
        payload: {
            uuid,
            lockDate
        }
    }
}

export function acquireEditLockError(uuid: string, error: any) {
    return {
        type: actionTypes.ACQUIRE_EDIT_LOCK_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function releaseEditLockRequest(uuid: string) {
    return {
        type: actionTypes.RELEASE_EDIT_LOCK_REQUEST,
        payload: {
            uuid
        }
    }
}

export function releaseEditLockSuccess(uuid: string) {
    return {
        type: actionTypes.RELEASE_EDIT_LOCK_SUCCESS,
        payload: {
            uuid,
        }
    }
}

export function releaseEditLockError(uuid: string, error: any) {
    return {
        type: actionTypes.RELEASE_EDIT_LOCK_ERROR,
        payload: {
            uuid,
            error
        }
    }
}

export function addManagedInstances(instances: { [instanceName: string]: any }) {
    return {
        type: actionTypes.ADD_MANAGED_INSTANCES,
        payload: {
            instances
        }
    }
}

export function registerToInstance(authId: string, authName: string,instanceName: string) {
    return {
        type: actionTypes.REGISTER_TO_INSTANCE,
        payload: {
            authId,
            authName,
            instanceName
        }
    }
}

export function deregisterFromInstance(authId: string, instanceName: string) {
    return {
        type: actionTypes.DEREGISTER_FROM_INSTANCE,
        payload: {
            authId,
            instanceName
        }
    }
}
