import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from "./store";
import {Route, Router} from "react-router";
import {createBrowserHistory} from "history";
import {Auth0Provider} from "./infrastructure/Auth0/Auth0Provider";

const history = createBrowserHistory();

const onRedirectCallback = (appState: any) => {
    console.log('onRedirectCallback', appState);
};


ReactDOM.render(
    <Provider store={configureStore()}>
        <Auth0Provider onRedirectCallback={onRedirectCallback}>
            <Router history={history}>
                <Route path="/" render={props =>
                    <App transfers={{}}
                         transfersHidden={0}
                         labsUpdateDate={0}
                         ethicsApprovalsUpdateDate={0}
                         patientsUpdateDate={0}
                         proceduresUpdateDate={0}
                         imagesUpdateDate={0}
                         scannersUpdateDate={0}
                         annotationTypesUpdateDate={0}
                         annotationsUpdateDate={0}
                         {...props}/>}/>
            </Router>
        </Auth0Provider>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
