import React, {Component} from 'react';

import './base.css';

import {
    Patient
} from "../../types/data/Patient";
import Table from "react-bootstrap/Table";

interface IPatientTableProps {
    patients?: Patient[] ;
    labs?: { [uuid: string]: string };
    onSelect: (uuid: string) => void;
}

interface IPatientTableState {

}

class PatientTable extends Component<IPatientTableProps, IPatientTableState> {

    render() {
        let tableRows: any[] = [];
        if (this.props.patients) {
            for (let patient of this.props.patients) {
                tableRows.push(
                    <tr onClick={(e) => this.props.onSelect(patient.uuid)}
                        key={`patient_${patient.uuid}`}>
                        <th>{patient.uuid}</th>
                        <th>{patient.foreignId}</th>
                        <th>{this.props.labs ? this.props.labs[patient.labUuid] : patient.labUuid}</th>
                        <th>{patient.sex}</th>
                        <th>{patient.ethnicity}</th>
                    </tr>
                )
            }
        }

        return <Table striped bordered hover responsive>
            <thead>
            <tr>
                <th>UUID</th>
                <th>Foreign ID</th>
                <th>Laboratory</th>
                <th>Sex</th>
                <th>Ethnicity</th>
            </tr>
            </thead>
            <tbody>
            {tableRows}
            </tbody>
        </Table>
    }
}

export default PatientTable;