import SeamlessImmutableFunc from "seamless-immutable";
import {Lab} from "../types/data/Lab";
import {Image} from "../types/data/Image";
import {Patient} from "../types/data/Patient";
import {Procedure} from "../types/data/Procedure";
import {Scanner} from "../types/data/Scanner";
import {AnnotationMode, Annotation} from "../types/data/Annotation"
import {Transfer} from "../types/data/Transfer";
import {EditLock} from "../types/data/EditLock";
import {Instance} from "../types/data/Instance";

export class MainState {
    public static LABS = () => "labs";
    public labs: {
        [uuid: string]: Lab
    } = {};

    public static LABS_UPDATE_DATE = () => "labsUpdateDate";
    public labsUpdateDate: number = 0;

    public static ETHIC_APPROVALS = () => "ethicsApprovals";
    public ethicsApprovals: { [uuid: string]: string } = {};

    public static ETHICS_APPROVALS_UPDATE_DATE = () => "ethicsApprovalsUpdateDate";
    public ethicsApprovalsUpdateDate: number = 0;

    public static PATIENTS = () => "patients";
    public patients: { [uuid: string]: Patient } = {};

    public static PATIENTS_UPDATE_DATE = () => "patientsUpdateDate";
    public patientsUpdateDate: number = 0;

    public static PROCEDURES = () => 'procedures';
    public procedures: { [patientUuid: string]: { [uuid: string]: Procedure } } = {};

    public static PROCEDURES_UPDATE_DATE = () => "proceduresUpdateDate";
    public proceduresUpdateDate: number = 0;

    public static IMAGES = () => 'images';
    public images: { [procedureUuid: string]: { [uuid: string]: Image } } = {};

    public static IMAGES_UPDATE_DATE = () => "imagesUpdateDate";
    public imagesUpdateDate: number = 0;

    public static SCANNERS = () => 'scanners';
    public scanners: { [uuid: string]: Scanner} = {};

    public static SCANNERS_UPDATE_DATE = () => "scannersUpdateDate";
    public scannersUpdateDate: number = 0;

    public static ANNOTATION_TYPES = () => 'annotationTypes';
    public annotationTypes: { [uuid: string]: AnnotationMode} = {};

    public static ANNOTATION_TYPES_UPDATE_DATE = () => "annotationTypesUpdateDate";
    public annotationTypesUpdateDate: number = 0;

    public static ANNOTATIONS = () => 'annotations';
    public annotations: { [imageUuid: string]: { [uuid: string]: Annotation} } = {};

    public static ANNOTATIONS_UPDATE_DATE = () => "annotationsUpdateDate";
    public annotationsUpdateDate: number = 0;

    public static TRANSFERS = () => 'transfers';
    public transfers: {[uuid: string]: Transfer} = {};

    public static TRANSFERS_HIDDEN = () => 'transfersHidden';
    public transfersHidden: number = 0;

    public static ADDING_ENTRIES = () => 'adding_entries';
    public addingEntries: {[tempId: string]: {
        type: string,
        tempId: string
    }} = {};

    public static EDIT_LOCKS = () => 'editLocks';
    public editLocks: {[uuid: string]: EditLock} = {}

    public static MANAGED_INSTANCES = () => 'managedInstances';
    public managedInstances: {[instanceName: string] :Instance} = {}
}

export const baseState = SeamlessImmutableFunc<MainState>(new MainState());
