export enum LOCK_STATUS {
    'REQUESTED',
    'ACQUIRED',
    'UNAVAILABLE',
    'ERROR',
    'RELEASING'
}

export class EditLock {
    uuid!: string;
    status!: LOCK_STATUS;
    type!: string;
    lockDate?: number;
}