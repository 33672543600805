import React, {Component} from 'react';

import './base.css';

import {Procedure} from "../../types/data/Procedure";
import ProcedureTable from "./ProcedureTable";
import {MainState} from "../../reducers/MainState";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {Redirect} from "react-router";
import {Image} from "../../types/data/Image";
import {
    loadAnnotations,
    loadEthicsApprovals,
    loadImages,
    loadLabs,
    loadPatients,
    loadProcedures
} from "../../types/actions/AsyncActionCreators";
import {fetchInterval} from "../../constants";
import {Auth0Context} from "../../infrastructure/Auth0/Auth0Provider";

interface IProcedureOverviewProps {
    procedures?: { [uuid: string]: Procedure };
    images?: {
        [procedureUuid: string]: {
            [uuid: string]: Image
        }
    },
    labsUpdateDate?: number
}

interface IProcedureOverviewState {
    addProcedure: boolean;
    selectedUuid: string;
}

interface IProcedureOverviewDispatch {
    loadEthicsApprovals: (authToken: string) => void;
    loadLabs: (authToken: string) => void;
    loadPatients: (authToken: string) => void;
    loadProcedures: (authToken: string) => void;
    loadImages: (authToken: string) => void;
    loadAnnotations: (authToken: string) => void;
}

const initialState: IProcedureOverviewState = {
    addProcedure: false,
    selectedUuid: ''
};

class ProcedureOverview extends Component<IProcedureOverviewProps & IProcedureOverviewDispatch, IProcedureOverviewState> {

    static contextType = Auth0Context;

    constructor(props: IProcedureOverviewProps & IProcedureOverviewDispatch) {
        super(props);
        this.state = initialState;
        this.onProcedureSelect = this.onProcedureSelect.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
    }

    componentDidMount(): void {
        if (this.props.labsUpdateDate && Date.now() - this.props.labsUpdateDate > fetchInterval) {
            this.context.callWithToken((authToken: string) => {
                this.props.loadEthicsApprovals(authToken);
                this.props.loadLabs(authToken);
                this.props.loadPatients(authToken);
                this.props.loadProcedures(authToken);
                this.props.loadImages(authToken);
                this.props.loadAnnotations(authToken);
            });
        }
    }

    onProcedureSelect(uuid: string) {
        this.setState({
            selectedUuid: uuid
        })
    }

    onClickAdd() {
        this.setState({
            addProcedure: true
        })
    }

    render() {
        return <div className="top d-flex flex-column align-items-start">
            <div className="d-flex flex-row justify-content-between w-100">
                <h3>
                    Procedures
                </h3>
                <Button type="button"
                        onClick={this.onClickAdd}> Add Procedure
                </Button>
            </div>
            <ProcedureTable
                procedures={this.props.procedures}
                images={this.props.images}
                onSelect={this.onProcedureSelect}/>

            {this.state.addProcedure && <Redirect to={'procedure/new'}/>}
            {this.state.selectedUuid && <Redirect to={`procedure/${this.state.selectedUuid}/details`}/>}
        </div>

    }
}

function mapDispatchToProps(dispatch: any): IProcedureOverviewDispatch {
    return {
        loadEthicsApprovals: (authToken: string) => dispatch(loadEthicsApprovals(authToken)),
        loadLabs: (authToken: string) => dispatch(loadLabs(authToken)),
        loadPatients: (authToken: string) => dispatch(loadPatients(authToken)),
        loadProcedures: (authToken: string) => dispatch(loadProcedures(authToken)),
        loadImages: (authToken: string) => dispatch(loadImages(authToken)),
        loadAnnotations: (authToken: string) => dispatch(loadAnnotations(authToken)),
    }
}

export default connect((state: MainState, ownProps: IProcedureOverviewProps) => {
    let proceduresFlat = {};
    for (let patientUuid in state.procedures) {
        proceduresFlat = {
            ...proceduresFlat,
            ...state.procedures[patientUuid]
        }
    }
    return {
        procedures: proceduresFlat,
        images: state.images
    }
}, mapDispatchToProps)(ProcedureOverview);
