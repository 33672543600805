import React, {Component} from 'react';

import './base.css';

import {Patient} from "../../types/data/Patient";
import PatientTable from "./PatientTable";
import {MainState} from "../../reducers/MainState";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {Redirect} from "react-router";

import {
    loadEthicsApprovals,
    loadLabs,
    loadPatients,
} from "../../types/actions/AsyncActionCreators";
import {Auth0Context} from '../../infrastructure/Auth0/Auth0Provider';
import {fetchInterval} from "../../constants";

interface IPatientOverviewProps {
    patients: { [uuid: string]: Patient };
    labs?: { [uuid: string]: string };
    labsUpdateDate?: number
}

interface IPatientOverviewState {
    addPatient: boolean;
    selectedUuid: string;
}

interface IPatientOverviewDispatch {
    loadEthicsApprovals: (authToken: string) => void;
    loadLabs: (authToken: string) => void;
    loadPatients: (authToken: string) => void;
}

const initialState: IPatientOverviewState = {
    addPatient: false,
    selectedUuid: ''
};

class PatientOverview extends Component<IPatientOverviewProps & IPatientOverviewDispatch, IPatientOverviewState> {

    static contextType = Auth0Context;

    constructor(props: IPatientOverviewProps & IPatientOverviewDispatch) {
        super(props);
        this.state = initialState;
        this.onPatientSelect = this.onPatientSelect.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
    }

    componentDidMount(): void {
        if (this.props.labsUpdateDate && Date.now() - this.props.labsUpdateDate > fetchInterval) {
            this.context.callWithToken((authToken: string) => {
                this.props.loadEthicsApprovals(authToken);
                this.props.loadLabs(authToken);
                this.props.loadPatients(authToken)
            })
        }
    }

    onPatientSelect(uuid: string) {
        this.setState({
            selectedUuid: uuid
        })
    }

    onClickAdd() {
        this.setState({
            addPatient: true
        })
    }

    render() {
        return <div className="top d-flex flex-column align-items-start">
            <div className="d-flex flex-row justify-content-between w-100">
                <h3>
                    Patients
                </h3>
                <Button type="button"
                        onClick={this.onClickAdd}> Add Patient
                </Button>
            </div>
            <PatientTable
                patients={Object.values(this.props.patients)}
                onSelect={this.onPatientSelect}
                labs={this.props.labs}/>
            {this.state.addPatient && <Redirect to={'patient/new'}/>}
            {this.state.selectedUuid && <Redirect to={`patient/${this.state.selectedUuid}/details`}/>}
        </div>

    }
}

function mapDispatchToProps(dispatch: any): IPatientOverviewDispatch {
    return {
        loadEthicsApprovals: (authToken: string) => dispatch(loadEthicsApprovals(authToken)),
        loadLabs: (authToken: string) => dispatch(loadLabs(authToken)),
        loadPatients: (authToken: string) => dispatch(loadPatients(authToken)),
    }
}

export default connect((state: MainState, ownProps: IPatientOverviewProps) => {
    let labs: { [uuid: string]: string } = {};
    for (let lab in state.labs) {
        labs[lab] = state.labs[lab].name
    }
    return {
        patients: state.patients,
        labs,
        labsUpdateDate: state.labsUpdateDate,
    }
}, mapDispatchToProps)(PatientOverview);
