import React, {Component} from 'react';

import './base.css';

import {
    Procedure
} from "../../types/data/Procedure";
import Table from "react-bootstrap/Table";
import {Image} from "../../types/data/Image";

interface IProcedureTableProps {
    procedures?: { [uuid: string]: Procedure };
    images?: {
        [procedureUuid: string]: {
            [uuid: string]: Image
        }
    }
    onSelect: (uuid: string) => void;
}

interface IProcedureTableState {

}

class ProcedureTable extends Component<IProcedureTableProps, IProcedureTableState> {

    render() {
        let tableRows: any[] = [];
        if (this.props.procedures) {
            for (let uuid in this.props.procedures) {
                if (this.props.procedures.hasOwnProperty(uuid)) {
                    let procedure = this.props.procedures[uuid];
                    tableRows.push(
                        <tr onClick={(e) => this.props.onSelect(uuid)}
                            key={`procedure_${uuid}`}>
                            <th>{procedure.uuid}</th>
                            <th>{procedure.name}</th>
                            <th>{procedure.patientUuid}</th>
                            <th>{procedure.procedureDate ? new Date(procedure.procedureDate).toLocaleDateString() : ''}</th>
                            <th>{procedure.tissueSourceSnomed}</th>
                            <th>{procedure.typeCancerSnomed}</th>
                            <th>{this.props.images && this.props.images.hasOwnProperty(uuid) ? Object.keys(this.props.images[uuid]).length : 0}</th>
                        </tr>
                    )
                }
            }
        }

        return <Table striped bordered hover responsive>
            <thead>
            <tr>
                <th>UUID</th>
                <th>Name</th>
                <th>Patient</th>
                <th>Date</th>
                <th>Tissue</th>
                <th>Cancer type</th>
                <th>#Images</th>
            </tr>
            </thead>
            <tbody>
            {tableRows}
            </tbody>
        </Table>
    }
}

export default ProcedureTable;