export enum TransferType {
    'IMAGE',
    'ANNOTATION'
}

export enum TransferDirection {
    'UPLOAD',
    'DOWNLOAD'
}

export class Transfer {
    ids!: {[idName: string]: string};
    displayName!: string;
    type!: TransferType;
    direction!: TransferDirection;
    progress: number = 0;
    startTime!: number;
}