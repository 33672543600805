import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import {Footer, Header} from "panakeia-react-common/dist";
import {AuthPage, InstanceManagementPage} from './components/DisplayPages';
import {Redirect, Route} from "react-router";
import {NavBar} from './components/NavBar';
import {connect} from "react-redux";

import Button from "react-bootstrap/Button";
import {Auth0Context} from './infrastructure/Auth0/Auth0Provider';

import styled from "styled-components"

import {
    loadEthicsApprovals,
    loadLabs,
    loadPatients,
    loadProcedures,
    loadImages,
    loadAnnotations,
} from "./types/actions/AsyncActionCreators";

import {MainState} from "./reducers/MainState";
import {AUTH_CONFIG} from "./constants";
import {TransferIndicator} from "./components/Toasts";
import {Transfer} from "./types/data/Transfer";
import {hideTransfers} from "./types/actions/ActionCreators";

const ToastWrapper = styled.div`
    position: absolute;
    right: 2px;
    width: 200px;
    z-index: 1000;
`

interface IAppDispatch {
    loadEthicsApprovals: (authToken: string) => void;
    loadLabs: (authToken: string) => void;
    loadPatients: (authToken: string) => void;
    loadProcedures: (authToken: string) => void;
    loadImages: (authToken: string) => void;
    loadAnnotations: (authToken: string) => void;
    hideTransfers: () => void;
}

interface IAppProps {
    transfers: { [uuid: string]: Transfer };
    transfersHidden: number;
    labsUpdateDate: number;
    ethicsApprovalsUpdateDate: number;
    patientsUpdateDate: number;
    proceduresUpdateDate: number;
    imagesUpdateDate: number;
    scannersUpdateDate: number;
    annotationTypesUpdateDate: number;
    annotationsUpdateDate: number;
}

interface IAppState {
    fetchedData: boolean;
}

class App extends Component<IAppProps & IAppDispatch, IAppState> {

    static contextType = Auth0Context;

    constructor(props: any) {
        super(props);

        this.state = {
            fetchedData: false
        };
    }

    componentDidMount(): void {
        console.log('App did mount');

    }

    componentDidUpdate(prevProps: IAppProps, prevState: IAppState) {

    }

    async login() {
        await this.context.loginWithRedirect();
    }

    async logout() {
        await this.context.logout({
            returnTo: AUTH_CONFIG.logout_uri
        })
    }

    render() {


        let page = !this.context.isAuthenticated ? <div className="page">
            <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                <h3 className="p-2">You need to be logged in to use PANtegrate</h3>
                <Button
                    className="btn-lg"
                    type="button" variant="secondary"
                    onClick={this.login.bind(this)}>Login</Button>
            </div>
        </div> : <div className="page">
            <NavBar logout={this.logout.bind(this)}/>
            <Route exact path="/instances"
                   render={(props) => <InstanceManagementPage {...props} managedInstances={{}} />}/>
            <Route exact path="/auth"
                   render={(props) => <AuthPage {...props}/>}/>
        </div>;


        let showTransfers = Object.values(this.props.transfers).filter(transfer => transfer.startTime >
            this.props.transfersHidden).length > 0;

        return (
            <div className="App">
                <ToastWrapper>
                    {showTransfers &&
                    <TransferIndicator transfers={this.props.transfers} onClose={this.props.hideTransfers}/>}
                </ToastWrapper>
                <Header
                    appName={'PANtegrate'}
                    appDescription={'Panakeia Data Management Tool'}/>
                {this.context.isAuthenticated && <Route path="/callback" render={_props => {
                    return <Redirect to="/instances"/>
                }}/>}
                {page}
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state: MainState, _ownProps: IAppProps) {
    return {
        transfers: state.transfers,
        transfersHidden: state.transfersHidden,
        labsUpdateDate: state.labsUpdateDate,
        ethicsApprovalsUpdateDate: state.ethicsApprovalsUpdateDate,
        patientsUpdateDate: state.patientsUpdateDate,
        proceduresUpdateDate: state.proceduresUpdateDate,
        imagesUpdateDate: state.imagesUpdateDate,
        scannersUpdateDate: state.scannersUpdateDate,
        annotationTypesUpdateDate: state.annotationTypesUpdateDate,
        annotationsUpdateDate: state.annotationsUpdateDate
    }
}

function mapDispatchToProps(dispatch: any): IAppDispatch {
    return {
        loadEthicsApprovals: (authToken: string) => dispatch(loadEthicsApprovals(authToken)),
        loadLabs: (authToken: string) => dispatch(loadLabs(authToken)),
        loadPatients: (authToken: string) => dispatch(loadPatients(authToken)),
        loadProcedures: (authToken: string) => dispatch(loadProcedures(authToken)),
        loadImages: (authToken: string) => dispatch(loadImages(authToken)),
        loadAnnotations: (authToken: string) => dispatch(loadAnnotations(authToken)),
        hideTransfers: () => dispatch(hideTransfers())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
