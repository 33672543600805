import React, {Component} from 'react';
import {Toast} from "react-bootstrap";
import {Transfer, TransferDirection, TransferType} from "../../types/data/Transfer";
import {Line} from 'rc-progress';
import styled from "styled-components"


interface ITransferIndicatorProps {
    transfers: { [uuid: string]: Transfer };
    onClose: () => void;
}

interface ITransferIndicatorState {
    expanded: boolean
}

const DetailToggle = styled.div`
    size: 10px;
    padding-bottom: -5px;
    color: -webkit-link;
`


class TransferIndicator extends Component<ITransferIndicatorProps, ITransferIndicatorState> {
    constructor(props: ITransferIndicatorProps) {
        super(props);
        this.state = {
            expanded: false
        }
    }


    render() {
        return (
            <Toast
                animation
                autohide
                key={'TransferIndicator'}
                onClose={this.props.onClose}
            >
                <Toast.Header>
                    <strong className="mr-auto">
                        {`Active transfers: ${Object.keys(this.props.transfers).length}`}
                    </strong>
                </Toast.Header>
                <Toast.Body>
                    {this.state.expanded ?
                        <div>
                            {Object.values(this.props.transfers).sort((a, b) => a.startTime - b.startTime).map(transfer =>
                                <div className="d-flex flex-column pb-2">
                                    <div className="pb-2">
                                    {`${transfer.direction === TransferDirection.UPLOAD ? 'Uploading' : 'Downloading'} ${transfer.displayName} (${transfer.type === TransferType.IMAGE ? 'image' : 'annotation'}): ${transfer.progress}%`}
                                    </div>
                                    <Line
                                        percent={transfer.progress}
                                        strokeWidth={4}
                                        strokeColor="#D3D3D3"
                                    />
                                </div>
                            )}
                            <DetailToggle onClick={() => {
                                this.setState({expanded: false})
                            }}>Collapse</DetailToggle>
                        </div>
                        : <div>
                            <DetailToggle onClick={() => {
                                this.setState({expanded: true})
                            }}>Expand</DetailToggle>
                        </div>}
                </Toast.Body>
            </Toast>
        );
    }
}

export default TransferIndicator;