import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import mainReducer from "./reducers/MainReducer";
import { baseState } from "./reducers/MainState";

const devToolsConfig = {
    maxAge: 1000,
}

export default function configureStore(initialState=baseState) {
    // @ts-ignore
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        //@ts-ignore
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsConfig)
        : compose;


    return createStore(
        mainReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk)),
    );
}