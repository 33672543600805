import React, {Component} from "react";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";

interface INavBarProps {
    logout: () => void;
}


class NavBar extends Component<INavBarProps, any> {


    render() {
        return (
            <div className="d-flex flex-row justify-content-between">
                <Link to="/instances">Instance management</Link>
                <Link to="/auth">Authentication</Link>
                <Button
                    type="button" variant="secondary"
                    onClick={this.props.logout}
                >
                    Logout
                </Button>
            </div>)
    }
}

export default NavBar
